<template>
  <el-header class="zg--header--main">
    <img class="zg--header--logo" src="https://image.qll-times.com/2021/04/e08a661764ea49c6a03d5278aa74283e.png">
    <label class="zg--header--title">商机圈子</label>
    <el-dropdown 
      @command="operation"
      trigger="click" class="zg--header--user">
      <span class="el-dropdown-link">
        {{ account.name }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="change">修改密码</el-dropdown-item>
        <el-dropdown-item command="cancellation">注销</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- 修改密码 -->
    <el-dialog width="500px" title="修改密码"
      :append-to-body="true"
      :modal-append-to-body="true"
      :visible.sync="dialogVisible">
      <el-form :model="form"  :rules="rules" ref="passwordForm" label-width="100px" status-icon class="demo-ruleForm">
        <el-form-item label="旧密码" prop="oldPass">
          <el-input type="password" placeholder="请输入原密码"
            v-model="form.oldPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <el-input type="password" placeholder="请输入新密码(8-20位数字字母组合)" maxlength="20"
            v-model="form.newPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" placeholder="请再次输入新密码" maxlength="20"
            v-model="form.checkPass" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateMyPassword()">确 定</el-button>
      </span>
    </el-dialog>
  </el-header>
</template>

<script>
import {cookie, promisify, isObject} from '@/js/Utils'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: true
    }
  },
  data(){
    let comparePassword = (rule, value, callback) => {
      if (value != this.form.newPass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      form: {
        oldPass: '',
        newPass: '',
        checkPass: ''
      },
      rules: {
        oldPass: [
          {required: true, message: '请输入您的原密码', trigger: 'change'},
        ],
        newPass: [
          {required: true, message: '请输入您的新密码', trigger: 'change'},
          {min: 8, message: '密码最少为8位数字字母的组合', trigger: 'change'},
        ],
        checkPass: [
          {required: true, message: '请确认您的密码', blur: 'change'},
          {validator: comparePassword, trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.getAccount();
  },
  mounted() {
    // 接收到登录指令后，重新读取账户信息
    this.$EventBus.$on('accountChange', (e) => {
      this.getAccount();
    });
  },
  methods: {
    // 从localStorage中读取账户信息
    getAccount(){
      this.account = this.$localStorage('ZGCMS_ACCOUNT');
    },

    // 用户注销、修改密码操作
    operation(command){
      if(command == "cancellation"){
        cookie('token', '');
        window.location.replace("#/login");
      }else if(command == "change"){
        this.dialogVisible = true;
      }
    },

    // 修改密码
    async updateMyPassword(){
      let getResult = await this.$refs['passwordForm'].validate;
      let result = await getResult();
      if(isObject(result)) return;

      let res = await this.$ZGManager.updateMyPassword(this.form.oldPass, this.form.newPass);
      if(res.status == 200){
        this.$message.success("密码修改成功，请重新登录");
        let timer = setTimeout(() => {
          this.operation('cancellation');
        }, 1000);
      }
    }
  }
}
</script>
<style scoped>

.el-dropdown-link {
  cursor: pointer;
}
</style>