<template>
  <div class="container-item">
    <!-- 标题 -->
    <title-comp :configs="configs"></title-comp>

    <!-- 无内容时的显示 -->
    <div v-if="configs.children.length < 1" class="list-none">从右侧设置栏添加企业资讯</div>

    <!-- 咨询列表 -->
    <template v-else>
      <div class="list-item" v-for="(item, index) in configs.children" :key="index"
        @click="redirectArticle(item.id)">
        <div class="list-item__logo">
          <img :src="item.logo || $configs.headImg">
        </div>
        <div class="list-item__info">
          <p class="van-multi-ellipsis--l2 list-item__info-title">{{item.title}}</p>
          <p class="list-item__info-time">{{item.updated_at | dateFormat('YYYY-MM-DD HH:mm:ss')}}</p>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import TitleComp from '@/websites/cms/components/displays/Title'
export default {
  props: ['configs', 'editModel'],
  components: { 'title-comp': TitleComp },
  methods: {
    // 阅读文章
    redirectArticle(id){
      if(this.editModel) return;
      let url = this.$route.params;
      this.$router.push(`/article/${url.cid}/${url.tid}/article/${id}/${url.sid}/${url.uid}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.container-item{
  background-color: #fff;
  padding-bottom: 0.3rem;
}
.list-none{
  width: 6.9rem;
  height: 4.2rem;
  line-height: 4.2rem;
  margin-left: 0.3rem;
  color: #fff;
  background-color: #ddd;
  text-align: center;
  font-size: 0.3rem;
}
.list-item{
  width: 6.3rem;
  height: 1.5rem;
  padding: 0.3rem;
  margin-bottom: 0.3rem;
  margin-left: 0.3rem;
  color: #999;
  background: #fff;
  border-radius: 0.08rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  &:nth-last-child(1){
    margin-bottom: 0;
  }
  .list-item__logo{
    width: 2rem;
    height: 1.5rem;
    background-color: #fafafa;
    position: relative;
    float: left;
    img{
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .list-item__info{
    width: 4rem;
    height: 1.5rem;
    float: right;
    position: relative;
    .list-item__info-title{
      color: #111;
      font-size: 0.3rem;
    }
    .list-item__info-time{
      position: absolute;
      bottom: 0;
    }
  }
}
</style>