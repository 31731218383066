<template>
  <div>
    <el-form label-position="left" label-width="115px">
      <p class="title">样式选择:(组件切换时，将保留当前选中样式的图集)</p>
      <ul class="style-ul">
        <li
          class="style-li"
          @click="selectStyle(item)"
          v-for="(item, index) in styleList"
          :key="index"
        >
          <img :class="{'active-img':item.type == configs.styleType}" :src="item.icon" />
          <p>{{item.name}}</p>
        </li>
      </ul>
      <div>
        <el-form-item
          class="url-input-box"
          :label="`视频地址${index+1}：`"
          style="padding-top:15px"
          v-for="(item, index) in configs.videoArry"
          :key="index"
        >
          <el-input v-model="item.url"></el-input>
          <a v-if="index == 0" href="https://baidu.com" target="_blank">如何正确填写视频地址？请看这里</a>
        </el-form-item>
      </div>
      <el-form-item :label="'视频封面：'">
        <ul class="picture-ul">
          <li
            class="picture-li"
            :class="{'active-picture':activePicture==index}"
            v-for="(item,index) in configs.videoArry"
            :key="index"
            :style="{backgroundImage:`url(${item.img || $configs.imageBg})`}"
            @click="activePicture = index"
          ></li>
        </ul>
      </el-form-item>
      <el-form-item>
        <div class="upload-image" v-if="configs.videoArry[activePicture].img == ''">
          <span @click="uploadImage()" class="el-icon-plus add-image"></span>
        </div>
        <div class="show-pictures" v-else>
          <div class="mask">
            <i class="el-icon-view" @click="showImgPopover = true"></i>
            <i class="el-icon-delete" @click="$set(configs.videoArry[activePicture], 'img', '')"></i>
          </div>
          <img :src="configs.videoArry[activePicture].img" />
        </div>
        <p class="size-tips">最佳尺寸：690x400px</p>
      </el-form-item>
      <div>
        <el-form-item
          class="url-input-box"
          :label="`视频标题${index+1}：`"
          style="padding-top:15px"
          v-for="(item, index) in configs.videoArry"
          :key="index"
        >
          <el-input v-model="item.title" maxlength="20" show-word-limit></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import mixin from "./mixin";
import imageDialog from "@/websites/cms/js/dialog";
export default {
  mixins: [mixin],
  props: ["configs"],
  data() {
    return {
      styleList: JSON.parse(JSON.stringify(this.$configs.videoStyle)),
      activePicture: 0, // 用户选中第几张图
    };
  },
  methods: {
    // 选择样式
    selectStyle(item) {
      if (this.configs.styleType != item.type) {
        this.configs.styleType = item.type;
        if (item.type == "big") {
          this.configs.videoArry.splice(1, 1);
        } else if (item.type == "small") {
          this.configs.videoArry.push({
            url: "https://v.qq.com/",
            img: "",
            title: "视频标题2",
          });
        }
      }
    },
    // 上传图片
    uploadImage(data) {
      let parmas = {
        type: "crooperImage",
        imageSize: 2,
        fixedArray: ["69", "40"],
        cropWidth: "690",
        cropHeight: "400",
        selected: (item) => {
          Vue.set(this.configs.videoArry[this.activePicture], "img", item.url);
        },
      };
      imageDialog.show(parmas);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  color: #606266;
  margin-bottom: 1em;
}
.style-ul {
  display: flex;
  margin-bottom: 1em;
  .style-li {
    margin-right: 35px;
    img {
      display: block;
      width: 80px;
      height: 80px;
      border: 1px solid transparent;
    }
    .active-img {
      border-color: #58b7ff;
    }
    p {
      padding: 10px 0;
      font-size: 14px;
      text-align: center;
      color: #606266;
    }
  }
}
.url-input-box {
  a {
    color: #2d7ee8;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    font-size: 14px;
    line-height: 14px;
    &:hover {
      color: #56a0f5;
      text-decoration: none;
      outline: 0;
    }
  }
}
.picture-ul {
  display: flex;
  flex-wrap: wrap;
  .picture-li {
    position: relative;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    background: transparent no-repeat 50% / cover;
  }
  .active-picture {
    border: 1px solid #58b7ff;
    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      width: 14px;
      height: 12px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAYAAAFRKKwcAAAAAXNSR0IArs4c6QAAAYdJREFUKBWVU7tKA1EQPbvE+CBIfCAYjIWIpBMExUISFBtB7DTZSIKVtUU6G/ULRPAHrKzSWGSjwXcjCjYWQbSQoJBCLASR6GaczTJ3s4YILuzeO+ecmTn3DgvUP4kckYo1O+ptBzSBhNYSJu2BkBLCs67eUEctxTDpmeuFbFbn4GyqH6FIlyP2MRO9eHEzVReBjEOKkYVTiZWAbXwy2CrEbBgolNjnBpFeNGHZhF8HFoaAu1eg+CbSP9aVEwqqFqJj18dsdFpiJUiaNFElXAkhK3cFbOe/ycSII9GJSKs/lg1vR4H9e0fgS5p4cLbA1iTQ6QfWzgXhY8oYXMi7Uya9cGNk5CnCY1zid4anPMonDdqqpgXSBeqpfGOXE+KN5VykocBynub4RrLcoc2VNd+pAukjGqxYuOWO3c3k431AnK93k6fx/uWoanPgIaXY7lN9cmYMWBx2ROEAsBMDyh9A5tJNtlmNLc9bVRw4Uu830AKsjwPZR+C67OUk0owclfhvGhDgv+sP0NSI4656Ij0AAAAASUVORK5CYII=)
        no-repeat 50%;
      content: "";
    }
  }
}
.upload-image {
  width: 104px;
  height: 104px;
  position: relative;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  .add-image {
    font-size: 30px;
    display: inline-block;
    width: 100%;
    line-height: 104px;
    text-align: center;
    color: #585858;
  }
}
.show-pictures {
  width: 104px;
  height: 104px;
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .mask {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    box-sizing: border-box;
    justify-content: space-between;
    i {
      line-height: 104px;
      cursor: pointer;
      font-weight: bold;
      color: hsla(0, 0%, 100%, 0.5);
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  &:hover {
    .mask {
      display: flex;
    }
  }
}
.size-tips {
  padding: 15px 0;
  color: #687382;
  font-size: 12px;
}
</style>
