<template>
  <div class="goods-container">
    <el-form label-position="left" label-width="80px">
      <!-- 案例名称 -->
      <el-form-item label="标题">
        <el-input v-model="configs.alias" maxlength="15" show-word-limit></el-input>
      </el-form-item>

      <!-- 案例封面 -->
      <el-form-item label="相关商品">
        <p class="tips-none">最多可上传3个商品，上下拖动可调整商品顺序
          <el-button @click="addGoods" class="button" type="primary">添加商品</el-button>
        </p>
      </el-form-item>
    </el-form>
    <el-table :data="configs.list" class="item">
      <!-- 商品图片 -->
      <el-table-column label="商品图片" width="80">
        <template slot-scope="scope">
          <div class="goods-logo" :style="{'background-image': `url(${ scope.row.logo })`}"></div>
        </template>
      </el-table-column>

      <!-- 商品名称 -->
      <el-table-column prop="name" label="商品名称"></el-table-column>

      <!-- 商品价格 -->
      <el-table-column label="商品单价">
        <template slot-scope="scope">{{ "￥" + scope.row.sales_price }}</template>
      </el-table-column>

      <!-- 删除按钮 -->
      <el-table-column prop="date" label="操作" width="60">
        <template slot-scope="scope">
          <span class="del" @click="delGoods(scope)">删除</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import mixin from "./mixin"
import selectGoods from "@/websites/cms/js/dialog"
export default {
  mixins: [mixin],
  props: ['configs'],
  data() {
    return {
      
    };
  },
  methods: {
    // 删除商品
    delGoods(item){
      this.configs.list.splice(item.$index, 1);
    },

    // 添加商品
    addGoods(){
      selectGoods.show({
        type: 'selectGoods',
        list: this.configs.list,
        selected: (item) =>{     
          this.configs.list = item;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.tips-none{
  color: #bbb;
  font-size: 12px;
  .button{
    float: right;
  }
}
.item{
  .goods-logo{
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
  .del{
    color: #ff0000;
    cursor: pointer;
  }
}
</style>
