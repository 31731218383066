<template>
  <div>
    <!-- 组件标题 -->
    <title-comp :configs="configs"></title-comp>

    <div class="manage">
      <draggable :list="configs.children">
        <transition-group>
          <div class="manage-item" v-for="(item, index) in configs.children" :key="item.id">
            <!-- 公司地址 -->
            <el-form v-if="item.type=='address'" label-position="left" label-width="80px">
              <el-form-item label="控件名称">
                <el-input v-model="item.alias" maxlength="15" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="详细地址" class="icon-group">
                <el-input v-model="item.address" placeholder="请输入详细地址">
                  <!-- <el-button slot="append" type="primary">选取地址</el-button> -->
                </el-input>
              </el-form-item>
              <!-- <el-form-item label="地图" class="icon-group">
                <el-radio v-model="item.map.show" :label="true">显示</el-radio>
                <el-radio v-model="item.map.show" :label="false">不显示</el-radio>
              </el-form-item> -->
            </el-form>

            <!-- 联系电话 -->
            <el-form v-if="item.type=='tel'" label-position="left" label-width="80px">
              <el-form-item label="控件名称">
                <el-input v-model="item.alias" maxlength="15" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="联系电话" class="icon-group">
                <el-input v-model="item.tel" maxlength="15" show-word-limit></el-input>
              </el-form-item>
            </el-form>
            <span @click="delItem(index)" class="zgcms close-button">&#xe654;</span>
          </div>
        </transition-group>
      </draggable>
    </div>

    <!-- 添加地址、添加电话按钮 -->
    <el-button @click="addItem('tel')" type="primary">添加电话</el-button>
    <el-button @click="addItem('address')" type="primary">添加地址</el-button>
  </div>
</template>
<script>
import mixin from './mixin'
import draggable from 'vuedraggable'
import TitleComp from '@/websites/cms/components/editors/Title'
export default {
  mixins: [mixin],
  props: ['configs'],
  components: { 'title-comp': TitleComp },
  methods: {
    // 添加公司地址或联系电话
    addItem(type){
      if(type == 'tel'){
        this.configs.children.push({
          id: this.$RandomCode(8),
          ...this.$configs.contactTel
        });
      }else if(type == 'address'){
        this.configs.children.push({
          id: this.$RandomCode(8),
          ...this.$configs.companyAddress
        });
      }
    },

    // 删除公司地址或联系电话
    delItem(index){
      this.configs.children.splice(index, 1);
    }
  },
};
</script>

<style lang="scss" scoped>
.manage-item{
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  position: relative;
  .close-button{
    padding: 5px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top: -12px;
    right: -12px;
  }
}
</style>
