<template>
  <div class="baseinfo">
    <div v-if="configs.desc" v-html="configs.desc" class="content"></div>
    <div v-else class="none">请在右侧添加文本</div>
  </div>
</template>
<script>
export default {
  props: ['configs']
};
</script>

<style lang="scss" scoped>
.content{
  padding: 0.25rem;
  word-wrap: break-word;
  word-break: normal;
  ::v-deep img{
    max-width: 100%;
  }
}
.none{
  width: 100%;
  height: 200px;
  line-height: 200px;
  color: #fff;
  background-color: #ddd;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
</style>