<template>
  <div>
    <el-form label-position="left" label-width="80px">
      <el-form-item label="隐藏边框">
        <el-switch v-model="configs.hideBox" active-color="#0091ff" inactive-color="#999"></el-switch>
      </el-form-item>
      <el-form-item label="内容设置">
        <el-checkbox v-model="configs.showMainTitle">主标题</el-checkbox>
        <el-checkbox v-model="configs.showSubtitle">副标题</el-checkbox>
      </el-form-item>
      <el-form-item label="文字位置">
        <el-radio v-model="configs.textAlign" label="left">左侧对齐</el-radio>
        <el-radio v-model="configs.textAlign" label="center">水平居中</el-radio>
      </el-form-item>
      <el-form-item label="主标题" v-if="configs.showMainTitle">
        <el-input v-model="configs.mainTitle"></el-input>
      </el-form-item>
      <el-form-item label="副标题" v-if="configs.showSubtitle">
        <el-input v-model="configs.subtitle"></el-input>
      </el-form-item>
      <el-form-item label="内容" v-if="configs.showSubtitle">
        <el-input
          type="textarea"
          :rows="4"
          v-model="configs.content"
          class="text-textarea"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import mixin from "./mixin";
export default {
  mixins: [mixin],
  props: ["configs"]
};
</script>

<style lang="scss" scoped>
.text-textarea {
  ::v-deep .el-input__count {
    background: none;
  }
}
.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 8px;
  border: 1px solid transparent;
  img {
    width: 100%;
    height: 100%;
  }
}
.icon-current {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAYBJREFUWAntmDtLA0EURr8RGyGFIogRQkAtUogIPhEbH4UpLYTsJoW1/0CxFrGwUjtLSxsRElbFLp2CVtqmFiwM+Ejc650sq3Gxu7I7yGyxjxkuc+bMN1uMKpSJYPJlMqBm6zBZnmazgNIVsgatQakBab3NoDUoNSCttxm0BqUGpPU2g9ag1IC03mbQGpQakNbbDP57g53SGf51vXtJWWrCAWFBnxoZA1jwaEL52PYbWGI4FU48ccC1K+p+e8U++XDZ2BeYEYCuR2MMd8IrOcjWfr0S+824FZr3P1BtwUXQFjPAylDQmAhg8ZxmfOCUEboibJjsA/JZ4KKWEGCpQmk2d8ZLmpru/4mX6wGKOWDnGnhuJATYBI54M/Tq4WfTwOpwAJJJAeujwN4N8PgStOl7rLvYKZPDcPlw+INbYGsKrf0xNwAc3gG1etgbPGPLIBEpUthsH/6dg7jLyznOuTt+AO6f2nuD99gMljwss6qRKEKds7ZRjbZ+fyuTT/k15ifXOmfVXsO9aAAAAABJRU5ErkJggg==)
    no-repeat top;
  background-size: 40px 40px;
}
</style>
