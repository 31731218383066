<template>
  <div>
    <el-form label-position="left" label-width="80px">
      <p class="title">样式选择(切换样式将导致当前的内容丢失)</p>
      <ul class="style-ul">
        <li
          class="style-li"
          v-for="(item, index) in styleList"
          :key="index"
          @mouseover="switchPictures(item)"
          @mouseout="switchPictures(item)"
          @click="selectStyle(item)"
        >
          <img
            :class="{ 'active-img': item.type == configs.styleType }"
            :src="item.icon || item.icon1"
          />
          <p>{{ item.name }}</p>
        </li>
      </ul>
      <p class="title">图片编辑</p>
      <p class="tips">{{ tipsText.tips1 }}</p>
      <div class="input-box" v-if="configs.styleType == 'sudoku'">
        <div class="input-content">
          <span class="input-title">行数</span>
          <el-input-number
            v-model="configs.rowsNum"
            controls-position="right"
            :min="1"
            :max="4"
            size="small"
            @change="modifyNum"
          ></el-input-number>
        </div>
        <div class="input-content">
          <span class="input-title">列数</span>
          <el-input-number
            v-model="configs.columnsNum"
            controls-position="right"
            :min="2"
            :max="4"
            size="small"
            @change="modifyNum"
          ></el-input-number>
        </div>
      </div>
      <ul class="picture-ul" v-if="configs.styleType != 'single'">
        <draggable :list="configs.imgArry">
          <transition-group>
            <li
              class="picture-li"
              :class="{ 'active-picture': activePicture == index }"
              v-for="(item, index) in configs.imgArry"
              :key="item.id"
              :style="{ backgroundImage: `url(${item || configs.defaultPicture})` }"
              @click="activePicture = index">
              <i
                class="el-icon-circle-close"
                v-if="configs.styleType == 'slider' && index > 1"
                @click.stop="deletePicture(index)"
              ></i>
            </li>
          </transition-group>
        </draggable>
        <li
          class="picture-add"
          v-if="configs.styleType == 'slider' && configs.imgArry.length < 10"
          @click="configs.imgArry.push('')">
          <span class="el-icon-plus"></span>
        </li>
      </ul>
      <div class="upload-image" v-if="!configs.imgArry[activePicture]">
        <file-upload
          v-if="configs.styleType == 'single'"
          size="2"
          accepts=".jpeg,.jpg,.png"
          @on-success="uploadImage"
        >
          <div class="upload-image" style="border: none">
            <span class="el-icon-plus add-image"></span>
          </div>
        </file-upload>
        <span
          v-else
          @click="uploadImage()"
          class="el-icon-plus add-image"
        ></span>
      </div>
      <div class="show-pictures" v-else>
        <div class="mask">
          <i class="el-icon-view" @click="showImgPopover = true"></i>
          <i
            class="el-icon-delete"
            @click="$set(configs.imgArry, activePicture, '')"
          ></i>
        </div>
        <img :src="configs.imgArry[activePicture]" />
      </div>
      <p class="size-tips">{{ tipsText.tips2 }}</p>
    </el-form>
    <div
      class="img-popover"
      v-if="showImgPopover"
      @click="showImgPopover = false"
    >
      <div class="img-box" @click.stop>
        <img :src="configs.imgArry[activePicture]" />
        <i class="el-icon-close" @click="showImgPopover = false"></i>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from "./mixin";
import imageDialog from "@/websites/cms/js/dialog";
import upload from "@/websites/cms/components/FileUpload";
export default {
  mixins: [mixin],
  props: ["configs"],
  data() {
    return {
      styleList: JSON.parse(JSON.stringify(this.$configs.imageStyle)),
      pictureNum: 1, // 图片数量
      activePicture: 0, // 用户选中第几张图
      showImgPopover: false, // 展示图片
    };
  },
  components: {
    "file-upload": upload,
  },
  computed: {
    tipsText: function () {
      let tipsText = {};
      switch (this.configs.styleType) {
        case "single":
          tipsText.tips1 = "(图片大小不超过2M,支持jpg、png格式)";
          tipsText.tips2 = "最佳尺寸：690*400px";
          return tipsText;
        case "slider":
          tipsText.tips1 =
            "(图片大小不超过2M,支持jpg、png格式，最多可上传10张)";
          tipsText.tips2 = "最佳尺寸：450*260px";
          return tipsText;
        case "three":
          tipsText.tips1 = "(图片大小不超过2M,支持jpg、png格式，最多可上传3张)";
          tipsText.tips2 = "最佳尺寸：420*350px";
          return tipsText;
        case "sudoku":
          tipsText.tips1 = `(图片大小不超过2M,支持jpg、png格式，最多可上传${this.pictureNum}张)`;
          tipsText.tips2 = "最佳尺寸：330*260px";
          return tipsText;
      }
    },
  },
  filters: {},
  methods: {
    // 鼠标移入滑块图时切换gif
    switchPictures(item) {
      if (item.type == "slider") {
        if (!item.icon || item.icon == item.icon1) {
          item.icon = item.icon2;
        } else {
          item.icon = item.icon1;
        }
      }
    },
    // 选择样式
    selectStyle(item) {
      this.configs.styleType = item.type;
      this.activePicture = 0;
      this.pictureNum = item.pictureNum;
      if (item.type == "sudoku") {
        this.configs.rowsNum = 2;
        this.configs.columnsNum = 2;
      }
    },
    // 上传图片
    uploadImage(data) {
      if (this.configs.styleType == "single") {
        Vue.set(this.configs.imgArry, this.activePicture, data.url);
      } else {
        let parmas = {
          type: "crooperImage",
          imageSize: 2,
          fixedArray: [],
          cropWidth: "",
          cropHeight: "",
          selected: (item) => {
            Vue.set(this.configs.imgArry, this.activePicture, item.url);
          },
        };
        if (this.configs.styleType == "slider") {
          parmas.fixedArray[0] = "45";
          parmas.fixedArray[1] = "26";
          parmas.cropWidth = "450";
          parmas.cropHeight = "260";
        } else if (this.configs.styleType == "three") {
          parmas.fixedArray[0] = "6";
          parmas.fixedArray[1] = "5";
          parmas.cropWidth = "420";
          parmas.cropHeight = "350";
        } else if (this.configs.styleType == "sudoku") {
          parmas.fixedArray[0] = "33";
          parmas.fixedArray[1] = "26";
          parmas.cropWidth = "330";
          parmas.cropHeight = "260";
        }
        imageDialog.show(parmas);
      }
    },
    // 删除图片
    deletePicture(index) {
      this.configs.imgArry.splice(index, 1);
    },
    // 类型为宫格图时，设置图片总数
    modifyNum() {
      this.pictureNum = this.configs.rowsNum * this.configs.columnsNum;
    },
  },
  watch: {
    pictureNum: {
      handler: function (newPictureNum) {
        if (this.configs.imgArry.length > newPictureNum) {
          for (
            let i = this.configs.imgArry.length - newPictureNum;
            i > 0;
            i--
          ) {
            this.configs.imgArry.splice(-1, 1);
          }
        } else if (this.configs.imgArry.length < newPictureNum) {
          let n = newPictureNum - this.configs.imgArry.length;
          for (let i = 0; i < n; i++) {
            this.configs.imgArry.push("");
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  color: #606266;
  margin-bottom: 1em;
}
.style-ul {
  display: flex;
  margin-bottom: 1em;
  .style-li {
    width: 80px;
    margin-right: 40px;
    img {
      width: 80px;
      height: 80px;
      border: 1px solid transparent;
    }
    .active-img {
      border: 1px solid #7fc0f6;
    }
    p {
      padding-top: 15px;
      font-size: 12px;
      text-align: center;
      color: #606266;
    }
  }
}
.tips {
  color: #999;
  font-size: 12px;
  margin-bottom: 1em;
}
.size-tips {
  padding: 15px 0;
  color: #687382;
  font-size: 12px;
}
.picture-ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  .picture-li {
    position: relative;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    margin-bottom: 10px;
    background: transparent no-repeat 50% / cover;
    display: inline-block;
    .el-icon-circle-close {
      position: absolute;
      display: none;
      width: 14px;
      height: 14px;
      top: -7px;
      right: -7px;
      color: red;
      cursor: pointer;
    }
    &:hover {
      .el-icon-circle-close {
        display: block;
      }
    }
  }
  .active-picture {
    border: 1px solid #58b7ff;
    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      width: 14px;
      height: 12px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAYAAAFRKKwcAAAAAXNSR0IArs4c6QAAAYdJREFUKBWVU7tKA1EQPbvE+CBIfCAYjIWIpBMExUISFBtB7DTZSIKVtUU6G/ULRPAHrKzSWGSjwXcjCjYWQbSQoJBCLASR6GaczTJ3s4YILuzeO+ecmTn3DgvUP4kckYo1O+ptBzSBhNYSJu2BkBLCs67eUEctxTDpmeuFbFbn4GyqH6FIlyP2MRO9eHEzVReBjEOKkYVTiZWAbXwy2CrEbBgolNjnBpFeNGHZhF8HFoaAu1eg+CbSP9aVEwqqFqJj18dsdFpiJUiaNFElXAkhK3cFbOe/ycSII9GJSKs/lg1vR4H9e0fgS5p4cLbA1iTQ6QfWzgXhY8oYXMi7Uya9cGNk5CnCY1zid4anPMonDdqqpgXSBeqpfGOXE+KN5VykocBynub4RrLcoc2VNd+pAukjGqxYuOWO3c3k431AnK93k6fx/uWoanPgIaXY7lN9cmYMWBx2ROEAsBMDyh9A5tJNtlmNLc9bVRw4Uu830AKsjwPZR+C67OUk0owclfhvGhDgv+sP0NSI4656Ij0AAAAASUVORK5CYII=)
        no-repeat 50%;
      content: "";
    }
  }
  .picture-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 1px dotted #58b7ff;
  }
}
.input-box {
  overflow: hidden;
  padding-bottom: 15px;
  .input-content {
    float: left;
    height: 32px;
    line-height: 100%;
    font-size: 14px;
    color: #606266;
    margin-right: 10px;
  }
}
.upload-image {
  width: 104px;
  height: 104px;
  position: relative;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  .add-image {
    font-size: 30px;
    display: inline-block;
    width: 100%;
    line-height: 104px;
    text-align: center;
    color: #585858;
  }
  // .single-upload {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   ::v-deep div {
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
  .upload-demo {
    width: 100%;
    height: 100%;
  }
}
.show-pictures {
  width: 104px;
  height: 104px;
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .mask {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    box-sizing: border-box;
    justify-content: space-between;
    i {
      line-height: 104px;
      cursor: pointer;
      font-weight: bold;
      color: hsla(0, 0%, 100%, 0.5);
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  &:hover {
    .mask {
      display: flex;
    }
  }
}
.img-popover {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 998;
  overflow-y: auto;
  .img-box {
    position: absolute;
    width: 520px;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    padding: 24px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    margin-bottom: 24px;
    img {
      width: 100%;
    }
    i {
      position: absolute;
      font-size: 18px;
      top: 7px;
      right: 7px;
      cursor: pointer;
      color: #8b8b8b;
      &:hover {
        color: #424242;
        font-weight: bold;
      }
    }
  }
}
</style>