<template>
  <div>
    <el-form label-position="left" label-width="80px">
      <!-- 案例名称 -->
      <el-form-item label="分隔高度">
        <el-input-number v-model="configs.height" :min="0" :max="1000"></el-input-number>
        <p class="tips">默认为30px，最大可输入1000</p>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import mixin from './mixin'
export default {
  mixins: [mixin],
  props: ['configs'],
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.tips{
  color: #bbb;
  font-size: 12px;
}
</style>
