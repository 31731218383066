<template>
  <div>
    <el-form label-position="left" label-width="80px">
      <p class="title">轮播图样式选择(组件切换时，将保留当前选中样式的图集)</p>
      <div class="style-box">
        <ul class="style-ul">
          <li
            class="style-li"
            v-for="(item, index) in styleList"
            :key="index"
            @click="configs.styleId = item.id"
          >
            <div class="img-content" :class="{'active':item.id == configs.styleId}">
              <img :src="item.img" />
            </div>
            <p>{{item.size}}</p>
          </li>
        </ul>
      </div>
      <p class="tips">图片大小不超过2M, 最多可添加10张图片，拖动可调整图片顺序</p>
      <div class="upload-box">
        <draggable :list="configs.imgArry">
          <transition-group>
            <div class="upload-block" v-for="(item, index) in configs.imgArry" :key="`img${index}`">
              <div class="upload-image" v-if="!item.img">
                <span @click="uploadImage(index)" class="el-icon-plus add-image"></span>
              </div>
              <div class="show-image" v-else>
                <div class="mask">
                  <i class="el-icon-view"></i>
                  <i class="el-icon-delete" @click="$set(item, 'img', '')"></i>
                </div>
                <img :src="item.img" />
              </div>
              <div class="jump-box">
                <div>
                  跳转页面: {{item.jump.name || '不跳转'}}
                  <el-dropdown @command="selectJump">
                    <span>
                      修改
                      <i class="el-icon-arrow-down"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <!-- <el-dropdown-item :command="beforeHandleCommand('commodity',index)">商品</el-dropdown-item> -->
                      <el-dropdown-item :command="beforeHandleCommand('custom',index)">自定义</el-dropdown-item>
                      <el-dropdown-item :command="beforeHandleCommand(false,index)">不跳转</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <el-input
                :readonly="true"
                class="url-box"
                type="textarea"
                :rows="2"
                v-model="item.jump.url"
                v-if="item.jump.type == 'custom'"
              ></el-input>
              <span
                class="delete-img-btn el-icon-circle-close"
                v-if="index != 0"
                @click="configs.imgArry.splice(index,1)"
              ></span>
            </div>
          </transition-group>
        </draggable>
      </div>
      <button
        class="add-img-btn"
        v-if="configs.imgArry.length < 10"
        @click="configs.imgArry.push({img: '', jump: false});"
      >
        <span>添加图片</span>
      </button>
    </el-form>
  </div>
</template>
<script>
import mixin from "./mixin";
import imageDialog from "@/websites/cms/js/dialog";
import draggable from "vuedraggable";
export default {
  mixins: [mixin],
  props: ["configs"],
  components: { draggable },
  data() {
    return {
      styleList: JSON.parse(JSON.stringify(this.$configs.swipeStyle)),
    };
  },
  methods: {
    // 上传图片
    uploadImage(index) {
      let parmas = {
        type: "crooperImage",
        imageSize: 2,
        fixedArray: [],
        cropWidth: "750",
        cropHeight: this.configs.imgHeight[this.configs.styleId],
        cropHeight: "",
        selected: item => {
          Vue.set(this.configs.imgArry[index], "img", item.url);
        }
      };
      if (this.configs.styleId == 0) {
        parmas.fixedArray[0] = "15";
        parmas.fixedArray[1] = "8";
        parmas.cropHeight = "400";
      } else if (this.configs.styleId == 1) {
        parmas.fixedArray[0] = "125";
        parmas.fixedArray[1] = "116";
        parmas.cropHeight = "696";
      } else if (this.configs.styleId == 2) {
        parmas.fixedArray[0] = "125";
        parmas.fixedArray[1] = "177";
        parmas.cropHeight = "1062";
      }
      imageDialog.show(parmas);
    },
    selectJump(command) {
      if (!command.command) {
        Vue.set(this.configs.imgArry[command.index], "jump", false);
      } else if (command.command == "commodity") {
        console.error("商品");
      } else if (command.command == "custom") {
        this.$prompt("请输入跳转链接", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /((http|https|ftp):(\/\/|\\\\)((\w)+[.]){1,}(net|com|cn|org|cc|tv|[0-9]{1,3})(((\/[\~]*|\\[\~]*)(\w)+)|[.](\w)+)*(((([?](\w)+){1}[=]*))*((\w)+){1}([\&](\w)+[\=](\w)+)*)*)/,
          inputErrorMessage: "请输入正确链接"
        })
          .then(({ value }) => {
            Vue.set(this.configs.imgArry[command.index], "jump", {
              type: command.command,
              name: "自定义",
              url: value
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入"
            });
          });
      }
    },
    beforeHandleCommand(item, index) {
      return {
        command: item,
        index: index
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  color: #606266;
  margin-bottom: 1em;
}
.style-box {
  width: 440px;
  .style-ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    .style-li {
      .img-content {
        width: 90px;
        height: 140px;
        border: 1px solid silver;
        img {
          width: 100%;
        }
      }
      .active {
        border-color: #2d7ee8;
      }
      p {
        display: block;
        padding: 10px 0;
        text-align: center;
        font-size: 14px;
        color: #606266;
      }
    }
  }
}
.tips {
  color: #999;
  font-size: 12px;
  margin-bottom: 1em;
}
.upload-box {
  width: 100%;
  .upload-block {
    position: relative;
    display: flex;
    margin-bottom: 12px;
    padding: 16px;
    color: #666;
    background-color: #f5f5f5;
    .upload-image {
      width: 150px;
      height: 80px;
      position: relative;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 8px;
      .add-image {
        font-size: 30px;
        display: inline-block;
        width: 100%;
        line-height: 80px;
        text-align: center;
        color: #585858;
      }
    }
    .show-image {
      width: 150px;
      height: 80px;
      position: relative;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-right: 8px;
      .mask {
        width: 100%;
        height: 100%;
        padding: 0 20px;
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        box-sizing: border-box;
        justify-content: space-between;
        i {
          line-height: 80px;
          cursor: pointer;
          font-weight: bold;
          color: hsla(0, 0%, 100%, 0.5);
        }
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
      &:hover {
        .mask {
          display: flex;
        }
      }
    }
    .jump-box {
      font-size: 14px;
      color: #666;
      span {
        color: #578bcf;
        cursor: pointer;
      }
    }
    .url-box {
      position: absolute;
      width: 300px;
      left: 175px;
      top: 45px;
    }
    .delete-img-btn {
      position: absolute;
      width: 28px;
      height: 28px;
      top: -8px;
      right: -8px;
      font-size: 28px;
      color: #cbcbcb;
      cursor: pointer;
    }
  }
}
.add-img-btn {
  height: 36px;
  color: #fff;
  background-color: #2d7ee8;
  border-color: #2d7ee8;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  font-weight: 400;
}
</style>
