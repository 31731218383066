<template>
  <div class="comp-container">
    <!-- 组件标题 -->
    <title-comp :configs="configs"></title-comp>

    <template v-for="item in configs.children">
      <!-- 公司地址 -->
      <div class="common-container item-content" v-if="item.type=='address'" :key="item.id">
        <p class="phone__title">{{item.alias}}</p>
        <div>
          <span class="phone__tel inline-block">{{item.address}}</span>
          <span class="el-icon-location-information phone__call" @click="callTel(item.tel)"></span>
        </div>
      </div>

      <!-- 联系电话 -->
      <div class="common-container item-content" v-if="item.type=='tel'" :key="item.id">
        <p class="phone__title">{{item.alias}}</p>
        <div>
          <span class="phone__tel" >{{item.tel}}</span>
          <span class="yxzs_icon phone__call" @click="callTel(item.tel)">&#xe66f;</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import TitleComp from '@/websites/cms/components/displays/Title'
export default {
  props: ['configs'],
  components: { 'title-comp': TitleComp },
  methods: {
    // 打电话
    callTel(tel){
      window.location.href = `tel:${tel}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.comp-container{
  background-color: #fff;
  padding-bottom: 0.1rem;
}
.common-container{
  width: 6.3rem;
  padding: 0.1rem 0.3rem 0.3rem 0.3rem;
  margin-left: 0.3rem;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}
.item-content{
  overflow: hidden;
  margin-bottom: 0.15rem;
  &:nth-last-child(1){
    margin-bottom: 0;
  }
  .phone__title{
    color: #999;
    font-size: 0.24rem;
    margin-bottom: 1em;
  }
  .phone__tel{
    color: #666;
    font-size: 0.3rem;
  }
  .phone__call{
    color: #6e87bd;
    font-size: 0.4rem;
    float: right;
  }
  .inline-block{
    display: inline-block;
    width: 5.6rem;
    word-break: break-all;
  }
}
</style>