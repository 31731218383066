<template>
  <div>
    <el-form label-position="left" label-width="80px">
      <!-- 案例名称 -->
      <el-form-item label="案例名称">
        <el-input v-model="configs.alias" maxlength="24" show-word-limit></el-input>
      </el-form-item>

      <!-- 案例封面 -->
      <el-form-item label="案例封面">
        <file-upload accepts=".png,.jpeg,.jpg" @on-success="uploadLogo">
          <div class="logo">
            <img v-if="configs.logo" :src="configs.logo">
            <template  v-else>
              <p class="add">+</p><p>立即上传</p>
            </template>
          </div>
        </file-upload>
      </el-form-item>

      <!-- 描述内容 -->
      <el-form-item label="描述内容">
        <el-input v-model="configs.desc" type="textarea" rows="6" 
          maxlength="100" show-word-limit placeholder="添加描述"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import mixin from './mixin'
import upload from "@/websites/cms/components/FileUpload"
export default {
  mixins: [mixin],
  props: ['configs'],
  data() {
    return {
      
    };
  },
  methods: {
    // 上传封面图
    uploadLogo(data){
      console.log(this.configs);
      this.configs.logo = data.url;
    },
  },
  components:{
    'file-upload': upload
  }
};
</script>

<style lang="scss" scoped>

.logo{
  width: 130px;
  height: 130px;
  border: 1px dashed #bbb;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  text-align: center;
  position: relative;
  overflow: hidden;
  .add{
    font-size: 50px;
    font-weight: 100;
    margin-top: 25px;
  }
  img{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
