<template>
  <div class="comp-container">
    <!-- 组件标题 -->
    <title-comp :configs="configs"></title-comp>

    <!-- 合作伙伴列表 -->
    <div class="list-content">
      <template v-if="configs.children.length < 1">
        <div v-for="item in 4" :key="item" class="list-item"
          :style="{backgroundImage: `url('${$configs.headImg}')`}"
          :class="{
            'list-item__l1': configs.type == 0, 
            'list-item__l2': configs.type == 1}">
        </div>
      </template>
      <div v-for="(item, index) in configs.children" :key="index" class="list-item"
        :style="{
          backgroundImage: `url('${item.img}')`}"
        :class="{
          'list-item__l1': configs.type == 0, 
          'list-item__l2': configs.type == 1}">
      </div>
    </div>
  </div>
</template>

<script>
import TitleComp from '@/websites/cms/components/displays/Title'
export default {
  props: ["configs"],
  components: { 'title-comp': TitleComp },
};
</script>

<style lang="scss" scoped>
.comp-container{
  background-color: #fff;
  padding-bottom: 0.1rem;
}
.list-content{
  width: calc(100% - 0.6rem);
  margin-left: 0.3rem;
  padding-top: 0.3rem;
  border-radius: 0.08rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  .list-item{
    float: left;
    margin-left: 0.3rem;
    margin-bottom: 0.3rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
  .list-item__l1{
    width: 1.9rem;
    height: 0.9095rem;
  }
  .list-item__l2{
    width: 1.35rem;
    height: 1.35rem;
  }
}
</style>