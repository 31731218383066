<template>
  <el-form label-position="left" label-width="80px">
    <!-- 组件标题 -->
    <el-form-item label="组件标题">
      <el-input v-model="configs.alias" maxlength="15" show-word-limit></el-input>
    </el-form-item>

    <!-- 组件图标 -->
    <el-form-item label="选择图标" class="icon-group">
      <span v-for="(item, index) in iconList"
        :key="index" 
        :class="{'icon-current': item.id == configs.icon.id}"
        @click="changeIcon(item, index)"
        class="zgcms icon">
        <img :src="item.url">
      </span>
    </el-form-item>
  </el-form>
</template>
<script>
import mixin from './mixin'
export default {
  mixins: [mixin],
  props: ['configs'],
  data() {
    return {
      iconList: this.$configs.iconList
    };
  },
  methods: {
    // 切换图标
    changeIcon(item, index){
      this.iconList.map(e => e.checked = false);
      let a = this.iconList[index];
      a.checked = true;
      this.iconList.splice(index, 1, a);
      this.configs.icon.id = item.id;
      this.configs.icon.url = item.url;
    }
  }
};
</script>

<style lang="scss" scoped>
.icon{
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 8px;
  border: 1px solid transparent;
  img{
    width: 100%;
    height: 100%;
  }
}
.icon-current{
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAYBJREFUWAntmDtLA0EURr8RGyGFIogRQkAtUogIPhEbH4UpLYTsJoW1/0CxFrGwUjtLSxsRElbFLp2CVtqmFiwM+Ejc650sq3Gxu7I7yGyxjxkuc+bMN1uMKpSJYPJlMqBm6zBZnmazgNIVsgatQakBab3NoDUoNSCttxm0BqUGpPU2g9ag1IC03mbQGpQakNbbDP57g53SGf51vXtJWWrCAWFBnxoZA1jwaEL52PYbWGI4FU48ccC1K+p+e8U++XDZ2BeYEYCuR2MMd8IrOcjWfr0S+824FZr3P1BtwUXQFjPAylDQmAhg8ZxmfOCUEboibJjsA/JZ4KKWEGCpQmk2d8ZLmpru/4mX6wGKOWDnGnhuJATYBI54M/Tq4WfTwOpwAJJJAeujwN4N8PgStOl7rLvYKZPDcPlw+INbYGsKrf0xNwAc3gG1etgbPGPLIBEpUthsH/6dg7jLyznOuTt+AO6f2nuD99gMljwss6qRKEKds7ZRjbZ+fyuTT/k15ifXOmfVXsO9aAAAAABJRU5ErkJggg==) no-repeat top;
  background-size: 40px 40px;
}
</style>
