<template>
  <div class="baseinfo">
    <p v-if="!configs.alias" class="none">请在右侧添加案例名称</p>
    <div v-else class="content">
      <p class="title">{{ configs.alias }}</p>
      <p class="desc">{{ configs.desc }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: ['configs']
};
</script>

<style lang="scss" scoped>
.baseinfo{
  .content{
    padding: 0.25rem;
    .title{
      color: #000;
      font-size: 0.36rem;
      line-height: 0.72rem;
    }
    .desc{
      color: #999;
      font-size: 0.26rem;
      line-height: 0.52rem;
    }
  }
  .none{
    line-height: 1rem;
    color: #fff;
    background-color: #ddd;
    text-align: center;
  }
}
</style>