<template>
  <div class="baseinfo">
    <p v-if="!configs.alias" class="none">请在右侧添加标签</p>
    <p v-else class="title">{{ configs.alias }}</p>
    <span class="tags" v-for="(item, index) in configs.tags" :key="index">{{ item }}</span>
  </div>
</template>
<script>
export default {
  props: ['configs']
};
</script>

<style lang="scss" scoped>
.baseinfo{
  padding: 0.25rem;
  .none{
    line-height: 1rem;
    color: #fff;
    background-color: #ddd;
    text-align: center;
  }
  .title{
    color: #000;
    font-size: 0.36rem;
    line-height: 0.72rem;
  }
  .tags{
    padding: 0.05rem 0.1rem;
    margin: 10px 10px 0 0;
    border-radius: 0.04rem;
    color: #fff;
    background-color: #6e87bd;
    display: inline-block;
  }
}
</style>