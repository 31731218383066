<template>
  <div>
    <!-- 组件标题 -->
    <title-comp :configs="configs"></title-comp>

    <!-- 企业简介 -->
    <el-form label-position="left" label-width="80px">
      <el-form-item label="企业简介">
        <el-input v-model="configs.desc" type="textarea" rows="6" 
          show-word-limit placeholder="添加描述"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import mixin from './mixin'
import TitleComp from '@/websites/cms/components/editors/Title'
export default {
  mixins: [mixin],
  props: ['configs'],
  components: { 'title-comp': TitleComp }
};
</script>