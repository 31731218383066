<template>
  <div class="tag-container">
    <el-form label-position="left" label-width="80px">
      <!-- 名称 -->
      <el-form-item label="标题">
        <el-input v-model="configs.alias" maxlength="15" show-word-limit></el-input>
      </el-form-item>

      <!-- 添加标签 -->
      <el-form-item label="添加标签" class="item-content">
        <p class="tips">
          <span style="color: #ff0000">双击标签可修改标签内容</span>
          ，添加标签的数量不超过20个
        </p>
        <div class="margin-em brief">
          <!-- 展示标签 -->
          <draggable :list="configs.tags">
            <transition-group>
              <el-tag class="show-tag" v-for="(item, index) in configs.tags" :key="item.id" closable
                :disable-transitions="false"
                @dblclick.native="updateCompanyTags(item, index)"
                @close="delCompanyTags(item)">{{item}}</el-tag>
            </transition-group>
          </draggable>

          <!-- 添加标签输入框 -->
          <el-input
            class="input-new-tag"
            v-if="tags.enabled"
            v-model="tags.value"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="inputCompanyTags"
            @blur="inputCompanyTags">
          </el-input>

          <!-- 添加标签 -->
          <el-button v-if="!tags.enabled && configs.tags.length < 20"
            class="button-new-tag"
            icon="el-icon-plus"
            size="small"
            @click="addCompanyTags">添加标签
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import mixin from './mixin'
export default {
  mixins: [mixin],
  props: ['configs'],
  data() {
    return {
      tags: {
        enabled: false,
        value: ""
      }
    };
  },
  methods: {
    // 删除企业标签
    delCompanyTags(item) {
      this.configs.tags.splice(this.configs.tags.indexOf(item), 1);
    },

    // 添加企业标签
    addCompanyTags(){
      if(this.configs.tags.length >= 20){
        return this.$message.error('标签数量不能超过20个');
      }
      this.tags.enabled = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    // 输入企业标签
    inputCompanyTags() {
      if (this.tags.value) {
        this.configs.tags.push(this.tags.value);
      }
      this.tags.enabled = false;
      this.tags.value = '';
    },

    // 修改企业标签
    async updateCompanyTags(item, index){
      let res = await this.$prompt('请输入标签内容', '提示', {
        inputValue: item,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /.{2,}/,
        inputErrorMessage: '标签最少填写2个字'
      });
      if(res.action != 'confirm') return;
      this.configs.tags.splice(index, 1, res.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-content{
  .tips{
    font-size: 12px;
    color: #bbb;
  }
  .brief{
    margin-top: 10px;
    span{
      color: #888888;
    }
    .input-new-tag{
      display: inline-block;
      width: 150px;
    }
    .show-tag{
      margin-right: 10px;
    }
    label{
      display: inline-block;
      width: 70px;
    }
  }
}
</style>
