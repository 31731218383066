<template>
  <div class="swipe" :style="{height: `${compHeight(configs.imgHeight[configs.styleId])}`}">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in configs.imgArry" :key="index">
        <img :src="item.img || dConfigs.swiperDefaultImage"
          :style="{height: `${compHeight(configs.imgHeight[configs.styleId])}`}">
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
import { Swipe, SwipeItem } from "vant";
Vue.use(Swipe);
Vue.use(SwipeItem);
import defaultConfigs from '@/websites/cms/js/template/CompConfigs'
export default {
  props: ["configs"],
  data() {
    return {
      dConfigs: defaultConfigs
    };
  },
  mounted() {
    console.log(this.$configs)
  },
  methods: {
    // 计算rem单位
    compHeight(height){
      let rem = height / 100;
      return rem + 'rem';
    }
  }
};
</script>

<style lang="scss" scoped>
.swipe {
  width: 100%;
  img {
    width: 100%;
  }
}
</style>