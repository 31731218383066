<template>
  <div class="video">
    <ul class="video-ul">
      <li v-for="(item, index) in configs.videoArry" class="video-li"
        :key="index"
        :class="`${configs.styleType}-li`">
        <div class="video"
          :style="{ backgroundImage: `url(${item.img || $configs.imageBg})` }"></div>
        <p class="title">{{ item.title }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["configs"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.video {
  width: 100%;
  .video-ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0;
    padding: 15px;
    box-sizing: border-box;
    .video-li {
      overflow: hidden;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      .video {
        position: relative;
        background: transparent no-repeat 50% / cover;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          background: url("https://resource.aijiatui.com/13632945694/company/website/04f5a86a9ee48e6ba143d7137caa56aa.png")
            no-repeat 50% / contain;
        }
      }
      .title {
        display: block;
        padding: 15px;
        color: #333;
        font-weight: 700;
        font-size: 15px;
      }
    }
    .big-li {
      width: 345px;
      .video {
        height: 200px;
        &:after {
          width: 60px;
          height: 60px;
        }
      }
    }
    .small-li {
      width: 164px;
      .video {
        height: 100px;
        &:after {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
</style>