<template>
  <div>
    <!-- 组件标题 -->
    <title-comp :configs="configs"></title-comp>

    <!-- 人员列表 -->
    <p class="text-tips">上下拖动可调整人员顺序</p>
    <draggable :list="configs.children">
      <transition-group>
        <div class="manage-item" v-for="(item, index) in configs.children" :key="item.id">
          <el-form  label-position="left" label-width="80px">
            <!-- 选择名片关联人员 -->
            <el-form-item label="名片信息" class="icon-group">
              <el-input :disabled="true" :placeholder="item.alias" class="relation-button">
                <template slot="append">
                  <div @click="bindCard(index)" class="qwerty">
                    关联成员
                  </div>
                </template>
              </el-input>
            </el-form-item>

            <!-- 是否跳转名片 -->
            <el-form-item label="是否跳转" class="icon-group">
              <el-radio v-model="item.isRedirect" :label="false">否</el-radio>
              <el-radio v-model="item.isRedirect" :label="true">是(跳转到该成员名片)</el-radio>
            </el-form-item>
          </el-form>

          <!-- 删除按钮 -->
          <span @click="updateList('del', index)" class="zgcms close-button">&#xe654;</span>
        </div>
      </transition-group>
    </draggable>

    <!-- 添加地址、添加电话按钮 -->
    <el-button @click="updateList('add')" type="primary">添加人员</el-button>
  </div>
</template>
<script>
import mixin from './mixin'
import TitleComp from '@/websites/cms/components/editors/Title'
import ResourceDialog from '@/websites/cms/js/dialog'
export default {
  mixins: [mixin],
  props: ['configs'],
  components: { 'title-comp': TitleComp },
  methods: {
    // 添加、删除人员列表
    updateList(type, index){
      if(type == 'add'){
        this.configs.children.push({
          id: this.$RandomCode(8),
          ...this.$configs.cardMember
        });
      }else{
        this.configs.children.splice(index, 1);
      }
    },

    // 关联人员
    bindCard(index){
      ResourceDialog.show({
        type: "selectStaff",
        selected: (data) => {
          let tmp = data[0].data;
          if(tmp.teamName){
            this.configs.children[index].alias = `${tmp.teamName} \\ ${tmp.name}`;
          }else{
            this.configs.children[index].alias = `${tmp.companyName} \\ ${tmp.name}`;
          }
          this.configs.children[index].data = tmp;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.text-tips{
  color: #999;
  margin-bottom: 12px;
}
.manage-item{
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  position: relative;
  .relation-button{
    padding: 0;
    ::v-deep .el-input-group__append{
      color: #fff;
      background: #1a9afd;
      border-color: #1a9afd;
    }
  }
  .close-button{
    padding: 5px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top: -12px;
    right: -12px;
    cursor: pointer;
  }
}
</style>