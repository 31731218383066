<template>
  <div class="title">
    <span class="icon" v-if="configs.icon.id != 999">
      <img :src="configs.icon.url">
    </span>
    <span class="text">{{ configs.alias }}</span>
  </div>
</template>
<script>
export default {
  props: ['configs']
};
</script>

<style lang="scss" scoped>
.title{
  color: #000;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 0.3rem;
}
.icon{
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  vertical-align: text-bottom;
  img{
    width: 100%;
    height: 100%;
  }
}
.text{
  display: inline-block;
  font-size: 0.36rem;
  font-weight: bold;
  line-height: 1.5;
  vertical-align: text-bottom;
  padding: 0.1rem;
}
</style>