<template>
  <div class="text">
    <div class="text-box"
      :class="{'text-box-hide': configs.hideBox}"
      :style="{textAlign:configs.textAlign}">
      <p class="main-title" v-if="configs.showMainTitle">{{configs.mainTitle}}</p>
      <p class="subtitle" v-if="configs.showSubtitle">{{configs.subtitle}}</p>
      <p v-if="configs.showSubtitle">{{configs.content}}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["configs"],
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.text {
  box-sizing: border-box;
  width: 100%;
  padding: 5px 15px 0;
  .text-box-hide{
    padding: 0!important;
    box-shadow: none!important;
  }
  .text-box {
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    color: #666;
    word-break: break-all;
    font-size: 0.28rem;
    .main-title {
      color: #333;
      font-weight: 700;
      font-size: 0.3rem;
    }
    .subtitle {
      font-weight: 400;
    }
  }
}
</style>