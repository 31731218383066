<template>
  <div>
    <title-comp :configs="configs"></title-comp>
    <p class="desc"><pre>{{ configs.desc }}</pre></p>
  </div>
</template>
<script>
import TtileComp from '@/websites/cms/components/displays/Title'
export default {
  props: ['configs'],
  components: { 'title-comp': TtileComp }
};
</script>

<style lang="scss" scoped>
.desc{
  width: 6.9rem;
  line-height: 0.52rem;
  margin-left: 0.3rem;
  color: #999;
  background: #fff;
  font-size: 0.26rem;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
  pre{
    padding: 0.3rem;
    word-break: break-all;
    white-space: pre-wrap;
  }
}
</style>