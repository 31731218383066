<template>
  <div>
    <title-comp :configs="configs"></title-comp>

    <div class="manage">
      <p class="manage-tips">上下拖动可调整人员顺序</p>
      <div class="manage-item" v-for="(item, index) in configs.children" :key="index">
        <!-- 人员管理 -->
        <el-form label-position="left" label-width="80px">
          <el-form-item label="头像">
            <div class="crooper-image">
              <div v-if="!item.headImg" @click="uploadHeadImg(index)" class="crooper-image__upload">
                <span style="font-size:30px">+</span><br>
                <span>点击上传</span>
              </div>
              <div v-else class="crooper-image__image">
                <img :src="item.headImg">
                <div class="crooper-image__mask" @click="uploadHeadImg(index)">替换</div>
              </div>
            </div>
            <p class="tips">建议尺寸：330*280</p>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="item.name" maxlength="10" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="职位">
            <el-input v-model="item.position" maxlength="10" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="简介">
            <el-input v-model="item.desc" type="textarea" 
              :rows="4" maxlength="500" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="跳转名片">
            <el-radio v-model="item.redirectCard" :label="false">否</el-radio>
            <el-radio v-model="item.redirectCard" :label="true">跳转到成员名片</el-radio>
            <el-input v-if="item.redirectCard" v-model="item.staffTips" :disabled="true">
              <template slot="append">
                <el-button type="primary" @click="selectStaff(index)">
                  {{item.xid ? "重新关联" : "关联成员"}}
                </el-button>
              </template>
            </el-input>
          </el-form-item>
          <span class="zgcms close-button" @click="updatePeople('del', index)">&#xe654;</span>
        </el-form>
      </div>
    </div>

    <!-- 添加人员按钮 -->
    <el-button type="primary" @click="updatePeople('add')">添加人员</el-button>
  </div>
</template>

<script>
import mixin from './mixin'
import resourceDialog from '@/websites/cms/js/dialog'
import TitleComp from '@/websites/cms/components/editors/Title'
export default {
  mixins: [mixin],
  components: { 'title-comp': TitleComp },
  props: ['configs'],
  data() {
    return {
    };
  },
  methods: {
    // 裁剪头像
    uploadHeadImg(index){
      resourceDialog.show({
        type: "crooperImage",
        fixedArray: ['33', '28'],
        cropWidth: 330,
        cropHeight: 280,
        imageEnlarge: 2,
        selected: (item) => {
          this.configs.children[index].headImg = item.url;
        }
      });
    },

    // 添加或删除人员
    updatePeople(type, index){
      switch(type){
        case 'add':
          this.configs.children.push({
            id: this.$RandomCode(8),
            ...this.$configs.teamMember
          });
          break;
        case 'del':
          this.configs.children.splice(index, 1);
          break;
        default: break;
      }
    },

    // 关联员工
    selectStaff(index){
      resourceDialog.show({
        type: "selectStaff",
        selected: (item) =>{
          if(item.length < 1) return;
          let tmp = item[0].data.teamId ? item[0].data.teamName + " \\ " : item[0].data.companyName + " \\ ";
          this.configs.children[index].staffTips = `${tmp}${item[0].label}`;
          this.configs.children[index].xid = item[0].id;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.icon{
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 8px;
  border: 1px solid transparent;
  img{
    width: 100%;
    height: 100%;
  }
}
.icon-current{
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAYBJREFUWAntmDtLA0EURr8RGyGFIogRQkAtUogIPhEbH4UpLYTsJoW1/0CxFrGwUjtLSxsRElbFLp2CVtqmFiwM+Ejc650sq3Gxu7I7yGyxjxkuc+bMN1uMKpSJYPJlMqBm6zBZnmazgNIVsgatQakBab3NoDUoNSCttxm0BqUGpPU2g9ag1IC03mbQGpQakNbbDP57g53SGf51vXtJWWrCAWFBnxoZA1jwaEL52PYbWGI4FU48ccC1K+p+e8U++XDZ2BeYEYCuR2MMd8IrOcjWfr0S+824FZr3P1BtwUXQFjPAylDQmAhg8ZxmfOCUEboibJjsA/JZ4KKWEGCpQmk2d8ZLmpru/4mX6wGKOWDnGnhuJATYBI54M/Tq4WfTwOpwAJJJAeujwN4N8PgStOl7rLvYKZPDcPlw+INbYGsKrf0xNwAc3gG1etgbPGPLIBEpUthsH/6dg7jLyznOuTt+AO6f2nuD99gMljwss6qRKEKds7ZRjbZ+fyuTT/k15ifXOmfVXsO9aAAAAABJRU5ErkJggg==) no-repeat top;
  background-size: 40px 40px;
}
.manage-tips{
  color: #666;
  margin-bottom: 12px;
}
.manage-item{
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  position: relative;
  .close-button{
    padding: 5px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top: -12px;
    right: -12px;
  }
  .crooper-image{
    width: 98px;
    height: 82px;
    color: #666;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    span{
      vertical-align: middle;
    }
    .crooper-image__image{
      width: 100%;
      height: 100%;
      position: relative;
      &:hover .crooper-image__mask{
        display: block;
      }
      .crooper-image__mask{
        width: 100%;
        height: 100%;
        line-height: 82px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.65);
        z-index: 1;
        position: relative;
        display: none;
      }
      img{
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .tips{
    color: #666;
    font-size: 12px;
  }
}
</style>
