import { render, staticRenderFns } from "./ImageText.vue?vue&type=template&id=00ba10b0&scoped=true&"
import script from "./ImageText.vue?vue&type=script&lang=js&"
export * from "./ImageText.vue?vue&type=script&lang=js&"
import style0 from "./ImageText.vue?vue&type=style&index=0&id=00ba10b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00ba10b0",
  null
  
)

export default component.exports