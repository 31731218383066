<template>
  <div>
    <!-- 单图文 -->
    <ul v-if="configs.styleType == 'single'" class="common-container">
      <template v-for="(item, index) in configs.children">
        <li class="common-item single" 
          v-if="index == 0" :key="index"
          :style="{flexDirection: configs.position == 'top' ? 'column' : 'column-reverse'}">
          <img class="single-img" :src="item.image || $configs.imageBg">
          <div class="common-text single-text" :style="{'text-align': item.align}">
            <p class="main-title" v-if="item.title.show">{{item.title.value}}</p>
            <p class="sub-title" v-if="item.subtitle.show">{{item.subtitle.value}}</p>
            <p class="details" v-if="item.details.show">{{item.details.value}}</p>
          </div>
        </li>
      </template>
    </ul>

    <!-- 列表图文 -->
    <ul v-if="configs.styleType == 'list'" class="common-container">
      <li class="common-item list"
        v-for="(item, index) in configs.children" :key="index">
        <div class="list-img" 
          :style="{backgroundImage: `url('${item.image || $configs.imageBg}')`}"></div>
        <div class="list-text">
          <p class="main-title" v-if="item.title.show">{{item.title.value}}</p>
          <p class="sub-title" v-if="item.subtitle.show">{{item.subtitle.value}}</p>
        </div>
      </li>
    </ul>

    <!-- 滑块图文 -->
    <div v-if="configs.styleType == 'slider'" class="common-container slider">
      <ul>
        <li v-for="(item, index) in configs.children" :key="index"
          :style="{ width: configs.fullList ? '2.8rem' : '4.4rem', 
            }">
          <div class="slider-img" 
            :style="{ backgroundImage: `url('${item.image || $configs.imageBg}')`,
                      height: configs.fullList ? '1.65rem' : '2.6rem' }"></div>
          <div class="slider-text">
            <p class="main-title" v-if="item.title.show">{{item.title.value}}</p>
            <p class="sub-title" v-if="item.subtitle.show">{{item.subtitle.value}}</p>
            <p class="details" v-if="item.details.show">{{item.details.value}}</p>
          </div>
        </li>
      </ul>
    </div>

    <!-- 宫格图文 -->
    <ul v-if="configs.styleType == 'sudoku'" class="common-container sudoku">
      <li v-for="(item, index) in configs.children" :key="index"
        :style="{
          width: configs.column == 2 ? '3.3rem' : configs.column == 3 ? '2.1rem' : '1.6rem'}">
        <div class="slider-img" 
          :style="{
            backgroundImage: `url('${item.image || $configs.imageBg}')`,
            height: configs.column == 2 ? '2.6rem': configs.column == 3 ? '1.65rem': '1.26rem'}"></div>
        <div class="slider-text">
          <p class="main-title" v-if="item.title.show">{{item.title.value}}</p>
          <p class="sub-title" v-if="item.subtitle.show">{{item.subtitle.value}}</p>
          <p class="details" v-if="item.details.show">{{item.details.value}}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["configs"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.common-container{
  padding: 0.3rem 0;
}
.common-item{
  width: 6.9rem;
}
.common-text{
  word-break: break-all;
}
.main-title{
  line-height: 1.8;
  color: #000;
  font-size: 0.32rem;
  font-weight: 700;
}
.sub-title{
  line-height: 1.8;
  color: #afafaf;
  font-size: 0.26rem;
}
.details{
  line-height: 1.8;
  color: 666;
  font-size: 0.26rem;
}
.single{
  margin-left: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  .single-img{
    width: 100%;
  }
  .single-text{
    padding: 0.3rem;
    background-color: #fff;
  }
}
.list{
  width: 6.3rem;
  padding: 0.3rem;
  margin-left: 0.3rem;
  margin-bottom: 0.3rem;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  &:nth-last-child(1){
    margin-bottom: 0;
  }
  .list-img{
    width: 1.8rem;
    height: 1.48rem;
    border-radius: 0.08rem;
    background: transparent no-repeat 50%/contain;
  }
  .list-text{
    width: calc(100% - 0.4rem);
    padding: 0 0.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.slider{
  width: calc(100% - 0.6rem);
  padding: 0.3rem;
  overflow-x: auto;
  overflow-y: hidden;
  ul{
    display: flex;
    width: max-content;
    li{
      width: 4.4rem;
      margin-right: 0.3rem;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      overflow: hidden;
      display: inline-block;
      .slider-img{
        width: 100%;
        background: transparent no-repeat 50%/contain;
      }
      .slider-text{
        padding: 0.3rem;
      }
    }
  }
}
.sudoku{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 0;
  li{
    margin-bottom: 0.3rem;
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
  }
  .slider-img{
    width: 100%;
    // height: 2rem;
    background: transparent no-repeat 50%/contain;
  }
  .slider-text{
    padding: 0.15rem;
  }
}
</style>