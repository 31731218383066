<template>
  <div class="richtext-editor">
    <vue-html5-editor :content="configs.desc" :height="500" @change="updateData"></vue-html5-editor>
  </div>
</template>
<script>
import mixin from './mixin'
import VueHtml5Editor from 'vue-html5-editor'
Vue.use(VueHtml5Editor, {
  name: "vue-html5-editor",
  showModuleName: true,
  // 配置图片模块
  image: {
    // 文件最大体积，单位字节  max file size
    sizeLimit: 512 * 1024,
    // 上传参数,默认把图片转为base64而不上传
    upload: {
      url: '/api/upload/file',
      fieldName: 'file'
    },
    // 压缩参数,默认使用localResizeIMG进行压缩,设置为null禁止压缩
    compress: {
      width: 1600,
      height: 1600,
      quality: 80
    },
    // 响应数据处理,最终返回图片链接
    uploadHandler(responseText) {
      let json = JSON.parse(responseText)
      if (json.status === 0) {
        return `https://image.qll-times.com/${json.data.md5}`;
      }
      return '';
    }
  },
  // 语言，内建的有英文（en-us）和中文（zh-cn）
  language: "zh-cn",
  // 自定义语言
  i18n: {
    //specify your language here
    "zh-cn": {
      "align": "对齐方式",
      "image": "图片",
      "list": "列表",
      "link": "链接",
      "unlink": "去除链接",
      "table": "表格",
      "font": "文字",
      "full screen": "全屏",
      "text": "排版",
      "eraser": "格式清除",
      "info": "关于",
      "color": "颜色",
      "please enter a url": "请输入地址",
      "create link": "创建链接",
      "bold": "加粗",
      "italic": "倾斜",
      "underline": "下划线",
      "strike through": "删除线",
      "subscript": "上标",
      "superscript": "下标",
      "heading": "标题",
      "font name": "字体",
      "font size": "文字大小",
      "left justify": "左对齐",
      "center justify": "居中",
      "right justify": "右对齐",
      "ordered list": "有序列表",
      "unordered list": "无序列表",
      "fore color": "前景色",
      "background color": "背景色",
      "row count": "行数",
      "column count": "列数",
      "save": "确定",
      "upload": "上传",
      "progress": "进度",
      "unknown": "未知",
      "please wait": "请稍等",
      "error": "错误",
      "abort": "中断",
      "reset": "重置"
    }
  },
  // 自定义要显示的模块，并控制顺序
  visibleModules: [
    "text",
    "color",
    "font",
    "align",
    "list",
    "link",
    "unlink",
    "tabulation",
    "image",
    "hr",
    "eraser",
    "undo"
  ],
})
export default {
  mixins: [mixin],
  props: ['configs'],
  data() {
    return {
      
    };
  },
  methods: {
    // 同步数据
    updateData(data) {
      this.configs.desc = data
    },
  }
};
</script>

<style lang="scss" scoped>
.richtext-editor{
  ::v-deep input{
    appearance: radio;
  }
}
</style>