<template>
  <div class="siteeditor">
    <!-- 导航栏 -->
    <el-header class="zg--header--main">
        <img class="zg--header--logo"  src="https://image.qll-times.com/2021/04/e08a661764ea49c6a03d5278aa74283e.png">
        <label class="zg--header--title">商机圈子平台</label>
        <div class="zg--header--button">
          <el-button @click="dialog.show=true" plain>选择模板</el-button>
          <el-button @click="saveConfigs" type="primary">保存</el-button>
        </div>        
    </el-header>

    <!-- 左侧组件 -->
    <div class="scroll-none-custom left">
      <div class="modular">
        <h3>基本组件</h3>
        <div class="item" v-for="item in BasicList" :key="item.type"
          @click="addComponents(item.type)">
          <i class="zgcms" v-html="item.icon"></i>
          <p>{{ item.name }}</p>
        </div>
      </div>
      <div class="basic">
        <h3>模块组件</h3>
        <div class="item" v-for="item in ModularList" :key="item.type"
          @click="addComponents(item.type)">
          <i class="zgcms" v-html="item.icon"></i>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>

    <!-- 中间官网展示部分 -->
    <div v-if="currentConfigs.show" class="center custom-scroll-bar" id="zgcmsCenter">
      <div class="show-area" id="zgcmsShowArea">
        <composite :configs="configs" :editModel="true"></composite>
      </div>
    </div>

    <!-- 右侧模块设置部分 -->
    <div class="right" v-if="configs.children.length > 0">
      <component ref="editor" :is="selectedComponent.comp" :configs="selectedComponent"></component>
    </div>

    <!-- 选择模板弹窗 -->
    <el-dialog title="模板选择" width="1200px" :visible.sync="dialog.show" center>
      <div class="dialog-container">
        <div v-for="(item, index) in dialog.list" :key="item.id" class="item"
          style="text-align: center">
          <el-image class="image" :src="item.url" lazy></el-image>
          <el-button @click="changeSiteTemplate(index)" 
            type="primary" class="item-button">替换</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  ZGcms, Component, Title, Text, Picture, Swipe, Video, ImageText, PlaceHolder,
  CompanyDesc, CompanyNews, ManageTeam, Partner, ConnectUs, Card, CompanyBrochure
} from '../../js/model'
import Header from '../../components/Header'
import Composite from '../../components/displays/Composite'
import EditorComponents from '../../components/editors'
import SiteConfigs from '@/websites/cms/js/template/Site'
const VueQr = () => import('vue-qr');
export default {
  components: {
    ...EditorComponents,
    'composite': Composite,
    'zg-header': Header,
    VueQr
  },
  data(){
    return {
      BasicList: [ // 基本组件
        { type: 1, name: '标题', icon: '&#xe61f;' },
        { type: 2, name: '文本', icon: '&#xe620;' },
        { type: 3, name: '图片', icon: '&#xe665;' },
        { type: 4, name: '轮播图', icon: '&#xe622;' },
        { type: 5, name: '视频', icon: '&#xe667;' },                                                                              
        { type: 6, name: '图文', icon: '&#xe670;' },
        { type: 7, name: '占位符', icon: '&#xe6c8;' },
        // {   type: 8, name: '表单', icon: '&#xe625;' },
      ],
      ModularList: [ // 模块组件
        { type: 101, name: '企业简介', icon: '&#xe624;' },
        { type: 102, name: '企业资讯', icon: '&#xe626;' },
        { type: 103, name: '管理团队', icon: '&#xe603;' },
        { type: 104, name: '合作伙伴', icon: '&#xe623;' },
        { type: 105, name: '联系我们', icon: '&#xe621;' },
        // { type: 106, name: '企业招聘', icon: '&#xe627;' },
        { type: 107, name: '名片列表', icon: '&#xe600;' },
        { type: 108, name: '企业宣传册', icon: '&#xe6c5;' }
      ],
      configs: new ZGcms({
        alias: "微官网",
        configs: {},
        children: [],
        comp: 'mweb'
      }),
      selectedComponent: {}, // 选中的组件数据
      currentConfigs: { // 查询到的企业官网数据
        id: 0,
        name: "",
        show: false                     
      },
      dialog: {
        show: false,
        type: 1,
        list: [
          {id: 0, name: "模板1", url: "https://img0.qll-times.com/cdn/zgimg/site-model-1.png"},
          {id: 1, name: "模板2", url: "https://img0.qll-times.com/cdn/zgimg/site-model-4.png"},
          {id: 2, name: "模板3", url: "https://img0.qll-times.com/cdn/zgimg/site-model-2.png"},
          {id: 3, name: "模板4", url: "https://img0.qll-times.com/cdn/zgimg/site-model-3.png"},
          {id: 4, name: "模板5", url: "https://img0.qll-times.com/cdn/zgimg/site-model-5.png"},
          // {id: 5, name: "模板6", url: "https://img0.qll-times.com/cdn/zgimg/site-model-6.png"}
        ]
      }
    }
  },
  created() {
    // 查询企业定制信息
    this.getConfigs();
  },
  mounted(){
    // 监听组件选中事件
    this.$EventBus.$on("selectComponent", e => this.selectComponentsPageScroll(e));
  },
  methods: {
    // 查询企业微官网定制信息
    async getConfigs(){
      let res = await this.$ZGManager.getcmstmzConfigs(3);
      if(res.status >= 200 && res.status < 300 && res.data){
        this.currentConfigs.id = res.data.id;
        this.currentConfigs.name = res.data.name;
        this.configs = JSON.parse(res.data.configs);
        this.selectComponentsPageScroll(this.configs.children[this.configs.children.length - 1]);
      }
      this.currentConfigs.show = true;
    },

    // 保存企业微官网定制信息
    async saveConfigs(){
      let res;
      let configs = JSON.parse(JSON.stringify(this.configs));
      configs.children.map(e => delete e.checked);
      if(this.currentConfigs.id > 0){
        res = await this.$ZGManager.updateSiteConfigs(this.currentConfigs.id, {
          name: this.currentConfigs.name,
          configs: JSON.stringify(configs)
        });
      }else{
        res = await this.$ZGManager.createSiteConfigs("系统微官网创建", "系统微官网创建", JSON.stringify(this.configs));
      }
      if(res.status == 200 && res.data){
        this.$message.success(this.currentConfigs.id > 0 ? "保存成功" : "创建成功");
      }
    },
    
    // 替换官网模板
    async changeSiteTemplate(index){
      let res = await this.$confirm('页面内容替换后无法找回，请谨慎操作', '更换模版将会替换现有页面内容，是否继续？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(res != 'confirm') return;
      this.configs = new ZGcms(JSON.parse(JSON.stringify(SiteConfigs[index])));
      this.dialog.show = false;
      this.$EventBus.$emit('createComponent');
    },

    // 选中组件页面滚动
    selectComponentsPageScroll(e){
      this.selectedComponent = e;
      // 如果选中的是最后一个组件，则移动页面到最底部
      if(e.id == this.configs.children[this.configs.children.length - 1].id){
        this.$nextTick(e => $("#zgcmsCenter").scrollTop($("#zgcmsShowArea").height()));
      }
    },

    // 添加组件
    addComponents(type) {
      switch (type) {
        case 1: this.configs.children.push(new Title()); break;
        case 2: this.configs.children.push(new Text()); break;
        case 3: this.configs.children.push(new Picture()); break;
        case 4: this.configs.children.push(new Swipe()); break;
        case 5: this.configs.children.push(new Video()); break;
        case 6: this.configs.children.push(new ImageText()); break;
        case 7: this.configs.children.push(new PlaceHolder()); break;
        case 101: this.configs.children.push(new CompanyDesc()); break;
        case 102: this.configs.children.push(new CompanyNews()); break;
        case 103: this.configs.children.push(new ManageTeam()); break;
        case 104: this.configs.children.push(new Partner()); break;
        case 105: this.configs.children.push(new ConnectUs()); break;
        case 105: this.configs.children.push(new ConnectUs()); break;
        case 107: this.configs.children.push(new Card()); break;
        case 108: this.configs.children.push(new CompanyBrochure()); break;
        default: break;
      }
      // 发出创建组件的事件
      this.$EventBus.$emit('createComponent');
      
      // 启用组件数据改动监听
      if(this.configs.children.length <= 1){
        let timer = setTimeout(() => {
          this.$refs.editor.setValue();
        }, 300);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.siteeditor{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.left{
  width: 330px;
  height: calc(100% - 64px);
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  float: left;
  box-shadow: 1px 0 rgba(0, 0, 0, 0.1);
  h3{
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 550;
    border-bottom: 1px solid #e1e1e1;
  }
  .item{
    display: inline-block;
    width: 110px;
    height: 110px;
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    box-sizing: border-box;
    color: #666;
    &:hover{
      color: #2d7ee8;
      background-color: rgba(45,126,232,.1);
      border-color: rgba(45,126,232,.1);
    }
    &:nth-child(3n + 1){
      border-right: transparent;
    }
    i{
      display: inline-block;
      width: 100%;
      line-height: 70px;
      font-size: 30px;
      text-align: center;
    }
    p{
      text-align: center;
      font-size: 14px;
      line-height: 40px;
    }
  }
  
  // 组件列表特殊样式
  .modular{
    .item{
      &:nth-last-child(1){
        border-bottom: transparent;
      }
    }
  }
  .basic{
    h3{
      border-top: 1px solid #e1e1e1;
    }
  }
}
.center{
  width: calc(100% - 880px);
  height: calc(100% - 64px);
  overflow-x: hidden;
  overflow-y: auto;
  float: left;
  .show-area{
    width: 375px;
    min-height: 603px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
  }
  @media screen and (max-width: 1450px) {
    .show-area{
      margin-top: 50px;
    }
  }
}
.right{
  width: 520px;
  height: calc(100% - 94px);
  background-color: #fff;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  float: right;
  padding: 15px;
}
.dialog-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .item{
    width: 220px;
    height: 440px;
    margin-bottom: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    position: relative;
    &:hover{
      .hover{
        display: block;
      }
    }
    .hover{
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.7);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      display: none;
      text-align: center;
      img{
        width: 80%;
        margin-top: 40px;
        margin-bottom: 40px;
        display: inline-block;
      }
    }
    .image{
      width: 100%;
      height: 100%;
      ::v-deep img{
        width: 100%;
        height: auto;
      }
    }
    .item-name{
      line-height: 28px;
      font-size: 14px;
      text-align: center;
    }
    .item-button{
      margin-top: 15px;
    }
  }
}
</style>