import { render, staticRenderFns } from "./CompanyNews.vue?vue&type=template&id=08a07822&scoped=true&"
import script from "./CompanyNews.vue?vue&type=script&lang=js&"
export * from "./CompanyNews.vue?vue&type=script&lang=js&"
import style0 from "./CompanyNews.vue?vue&type=style&index=0&id=08a07822&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a07822",
  null
  
)

export default component.exports