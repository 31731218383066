let components = {};
let requireComponent = require.context(
  // 其组件目录的相对路径
  "./",
  // 是否查询其子目录
  false,
  // 匹配基础组件文件名的正则表达式
  /\w+\.vue$/
);
requireComponent.keys().forEach(fileName => {
  //排除自己&Component.vue
  if (/(Composite|Component)\.vue$/.test(fileName)) return;
  // 获取组件配置
  const componentConfig = requireComponent(fileName);
  // 获取组件的命名
  const componentName = fileName
    .replace(/^\.\/(.*)\.\w+$/, "z-$1")
    .toLowerCase();
  components[componentName] = componentConfig.default || componentConfig;
});

export default components