<template>
  <div>
    <!-- 上传按钮 -->
    <el-upload
      class="upload-demo"
      action="/api/upload/file"
      :accept="accepts"
      :show-file-list="false"
      :before-upload="uploadCheck"
      :on-progress="uploadprogres"
      :on-success="uploadFile"
      :multiple="false"
      >
      <slot>
        <el-button size="small" type="primary">上传文件</el-button>
      </slot>
    </el-upload>

    <!-- 文件进度条 -->
    <el-dialog center title="上传进度" width="600px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="isUploadProgres">
      <p style="color: #ff0000">提示：文件上传完毕前请不要关闭/刷新此页面</p><br>
      <el-progress :text-inside="true" :stroke-width="16" :percentage="uploadProgresNum"></el-progress>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    accepts: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      isUploadProgres: false,
      uploadProgresNum: 0
    }
  },
  methods: {
    // 限制上传大小
    uploadCheck(file){
      if(!this.size) return true;
      if((this.size * 1024) < (file.size / 1024)){
        this.$message.error(`您上传的文件大小超过了${this.size}M`);
        return false;
      }
    },

    // 上传文件
    uploadFile(res, file){
      if(res.status == 0){
        this.$emit('on-success', res.data);
      }else{
        this.$emit('on-fail', "文件上传失败，请稍后再试");
      }
    },

    // 上传进度条
    uploadprogres(e){
      if(e.percent >= 100){
        this.isUploadProgres = false;
      }else{
        this.uploadProgresNum = Math.ceil(e.percent);
        this.isUploadProgres = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>