import { debounce } from "../../../../js/Utils"
export default {
  data() {
    return {
      propsConfigs: {},
    }
  },
  created() {
    this.watch = true; // 默认开启监听
    this.unwatchs= []; // 监听记录列表
  },
  methods: {

    // 监听组件数据的改动
    setValue () {
      // 取消监听
      this.unwatchs.length > 0 && this.unwatchs.splice(0, this.unwatchs.length).forEach(unwatch => unwatch());
      
      // 设置监听
      this.unwatchs.push(this.$watch("propsConfigs", debounce(function(newVal){
        console.log("setvalue监听", newVal);
        this.watch && this.$EventBus.$emit('updateComponent', newVal);
      }, 500), { deep: true}));
      this.afterSetValue();
    },

    //各组件需在设置完值之后执行相应的方法，可在组件内部重写该方法
    afterSetValue(){
      console.log("afterSetValue");
    }
  }
}
