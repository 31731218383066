<template>
  <div class="container-item">
    <!-- 标题 -->
    <title-comp :configs="configs"></title-comp>

    <!-- 团队成员列表 -->
    <div class="list-container">
      <div class="list-item" v-for="(item, index) in configs.children" :key="index">
        <div class="list-item__img">
          <img v-if="item.headImg" :src="item.headImg">
        </div>
        <div class="list-item__info">
          <p v-if="item.name" class="list-item__info-name">{{item.name}}</p>
          <p v-if="item.position" class="list-item__info-position">{{item.position}}</p>
          <pre v-if="item.desc" class="van-hairline--top list-item__info-desc">{{item.desc}}</pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TitleComp from '@/websites/cms/components/displays/Title'
export default {
  props: ['configs', 'editModel'],
  components: { 'title-comp': TitleComp },
};
</script>

<style lang="scss" scoped>
.list-container{
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
}
.list-item{
  width: 3.3rem;
  min-height: 100px;
  margin-bottom: 0.3rem;
  margin-left: 0.3rem;
  border-radius: 0.08rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  .list-item__img{
    width: 100%;
    height: 2.8rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .list-item__info{
    padding: 0.2rem;
    word-break: break-all;
    .list-item__info-name{
      color: #111;
      font-size: 0.3rem;
    }
    .list-item__info-position{
      color: #bbb;
      padding-top: 0.2rem;
      font-size: 0.26rem;
    }
    .list-item__info-desc{
      line-height: 0.4rem;
      margin-top: 0.2rem;
      padding-top: 0.2rem;
      color: #666;
      font-size: 0.26rem;
      white-space: pre-wrap;
    }
  }
}
</style>