<template>
  <div class="picture">
    <ul :class="`${configs.styleType}-ul`">
      <li v-for="(item, index) in configs.imgArry"
        :class="className"
        :key="index"
        :style="{backgroundImage:(configs.styleType == 'single'?'':`url(${item ||configs.defaultPicture})`)}">
        <img :src="item || configs.defaultPicture" v-if="configs.styleType == 'single'" />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["configs"],
  data() {
    return {};
  },
  computed: {
    className: function() {
      if (this.configs.styleType == "sudoku") {
        return `${this.configs.styleType}-li li-${this.configs.columnsNum}`;
      } else {
        return `${this.configs.styleType}-li`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.picture {
  width: 100%;
  overflow-x: hidden;
  background-color: #fff;
  .single-ul {
    width: 100%;
    .single-li {
      background: transparent no-repeat 50% / cover;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .slider-ul {
    width: 100%;
    padding: 0.3rem;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      /*隐藏滚轮*/
      display: none;
    }
    .slider-li {
      display: inline-block;
      width: 4.4rem;
      height: 2.54rem;
      margin-right: 0.3rem;
      border-radius: 0.08rem;
      box-shadow: 0 0.04rem 0.1rem 0 rgba(0, 0, 0, 0.1);
      background: transparent no-repeat 50% / cover;
    }
  }
  .three-ul {
    display: flex;
    padding: 0.3rem;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    .three-li {
      width: 2.36rem;
      height: 1.36rem;
      background: transparent no-repeat 50% / cover;
      &:first-child {
        width: 4.2rem;
        height: 3rem;
      }
      &:last-child {
        position: absolute;
        right: 0.3rem;
        bottom: 0.3rem;
      }
    }
  }
  .sudoku-ul {
    flex-wrap: wrap;
    display: flex;
    padding: 0.3rem;
    .sudoku-li {
      margin-right: 0.3rem;
      margin-bottom: 0.3rem;
      background: transparent no-repeat 50% / cover;
    }
    .li-2 {
      width: 3.3rem;
      height: 2.6rem;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    .li-3 {
      width: 2.1rem;
      height: 2.1rem;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    .li-4 {
      width: 1.5rem;
      height: 1.5rem;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
</style>