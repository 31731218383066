<template>
  <div>
    <el-form label-position="left" label-width="80px">
      <!-- 组件标题 -->
      <title-comp :configs="configs"></title-comp>

      <!-- 添加按钮 -->
      <div class="news-add__button">
        <el-button type="primary" @click="addResource">添加宣传册</el-button>
        <p>上下拖动可调整宣传册顺序</p>
      </div>

      <!-- 列表 -->
      <el-table :data="configs.children">
        <el-table-column prop="date" label="封面" width="80px">
          <template slot-scope="scope">
            <div class="news-list__logo">
              <img :src="scope.row.config.logo || $configs.headImg">
            </div>
          </template>
        </el-table-column>
        <el-table-column label="标题" header-align="center">
          <template slot-scope="scope">
            <p style="text-align:center">{{scope.row.title}}</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="60px" header-align="center">
          <template slot-scope="scope">
            <el-link @click="delResource(scope)" 
              type="danger" :underline="false">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>
<script>
import mixin from './mixin'
import TitleComp from '@/websites/cms/components/editors/Title'
import ResourceDialog from '@/websites/cms/js/dialog'
import draggable from 'vuedraggable'
export default {
  mixins: [mixin],
  components: { 'title-comp': TitleComp },
  props: ['configs'],
  data() {
    return {

    };
  },
  methods: {
    // 添加资讯
    addResource(){
      ResourceDialog.show({
        type: 'selectBrochure',
        list: this.configs.children,
        selected: (data) => {
          this.configs.children = data;
        }
      });
    },

    // 删除资讯
    delResource(item){
      this.configs.children.splice(item.$index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.news-add__button{
  margin-bottom: 12px;
  p{
    line-height: 36px;
    color: #c9c9c9;
    float: right;
    
  }
}
.news-list__logo{
  width: 58px;
  height: 58px;
  background-color: #fafafa;
  position: relative;
  img{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
