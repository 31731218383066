<template>
  <div>
    <!-- 标题 -->
    <title-comp :configs="configs"></title-comp>

    <div class="item-container" 
      v-for="(item, index) in configs.children" :key="index">
      <div class="item-container__info">
        <p>{{item.data.name}}</p>
        <p>{{item.data.position}}</p>
        <p>手机:{{item.data.phoneNumber}}</p>
        <p>邮箱:{{item.data.email}}</p>
      </div>
      <div class="item-container__headimg bg-img-cover-center"
        :style="{
          backgroundImage: `url('${item.data.headImg || $configs.headImg}')`}">
      </div>
    </div>
  </div>
</template>
<script>
import TitleComp from '@/websites/cms/components/displays/Title'
export default {
  props: ['configs'],
  components: { 'title-comp': TitleComp },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
.item-container{
  width: 6.3rem;
  padding: 0.3rem;
  margin-left: 0.3rem;
  margin-bottom: 0.3rem;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  &:nth-last-child(1){
    margin-bottom: 0;
  }
  .item-container__info{
    width: 4.2rem;
    height: 2rem;
    color: #999;
    font-size: 0.24rem;
    float: left;
    position: relative;
    p{
      width: 100%;
      position: absolute;
    }
    p:nth-child(1){
      color: #000;
      font-size: 0.32rem;
    }
    p:nth-child(2){
      bottom: 1.1rem;
    }
    p:nth-child(3){
      bottom: 0.4rem;
    }
    p:nth-child(4){
      bottom: 0;
    }
  }
  .item-container__headimg{
    width: 2rem;
    height: 2rem;
    float: right;
  }
}
</style>