<template>
  <div class="goods-container">
    <el-form label-position="left" label-width="80px">
      <!-- 案例名称 -->
      <el-form-item label="标题">
        <el-input v-model="configs.alias" maxlength="8" show-word-limit placeholder="最多显示八个字"></el-input>
      </el-form-item>

      <!-- 案例封面 -->
      <el-form-item label="相关案例">
        <p class="tips-none">最多可上传3个案例，上下拖动可调整商品顺序
          <el-button @click="addCase" class="button" type="primary">添加案例</el-button>
        </p>
      </el-form-item>
    </el-form>
    <el-table :data="configs.list" class="item">
      <!-- 案例名称 -->
      <el-table-column prop="title" label="案例名称"></el-table-column>

      <!-- 创建者 -->
      <el-table-column prop="userName" label="创建者"></el-table-column>

      <!-- 删除按钮 -->
      <el-table-column prop="date" label="操作" width="60">
        <template slot-scope="scope">
          <span class="del" @click="delCase(scope)">删除</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import mixin from "./mixin"
import selectItem from "@/websites/cms/js/dialog"
export default {
  mixins: [mixin],
  props: ['configs'],
  data() {
    return {
      
    };
  },
  methods: {
    // 删除商品
    delCase(item){
      this.configs.list.splice(item.$index, 1);
    },

    // 添加商品
    addCase(){
      selectItem.show({
        type: 'selectCase',
        list: this.configs.list,
        selected: (item) =>{     
          console.log(item);
          this.configs.list = item;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.tips-none{
  color: #bbb;
  font-size: 12px;
  .button{
    float: right;
  }
}
.item{
  .del{
    color: #ff0000;
    cursor: pointer;
  }
}
</style>
