<template>
  <div id="zgcmsSection" class="section">
    
    <!-- 渲染组件 -->
    <template v-if="configs.children && configs.children.length > 0">
      <div v-for="(item, index) in configs.children" 
        :key="item.id" 
        :data-id="item.id"
        :class="{'is-active': item.checked}"
        @click="select(index)"
        class="component">

        <!-- 动态渲染组件 -->
        <component :is="item.comp" :configs="item" :editModel="editModel"></component>

        <template v-if="editModel">
          <!-- 蒙版，未选中状态时呈灰黑色 -->
          <div class="mask"></div>

          <!-- 上移/下移/删除操作按钮 -->
          <div class="button" v-if="item.checked">
            <!-- 上移按钮 -->
            <span v-if="showMoveUpButton(item.configs, index)" 
              @click.stop="moveUP(index)" class="zgcms">&#xe656;</span>

            <!-- 下移按钮 -->
            <span v-if="!item.configs.hideDel && index < configs.children.length - 1" 
              @click.stop="moveDown(index)" class="zgcms">&#xe657;</span>

            <!-- 删除按钮 -->
            <span v-if="!item.configs.hideDel" 
              @click.stop="delCom(index)" class="zgcms">&#xe654;</span>
          </div>
        </template>
      </div>
    </template>

    <!-- 官网没有内容时的提示 -->
    <p v-else class="tips">暂无内容</p>
  </div>
</template>

<script>
// 引入display的组件模板
import components from '../displays'
export default {
  components: components,
  props: {
    configs: {
      type: Object,
      default: function() {}
    },
    editModel: false
  },
  data() {
    return {
      isInit: false, // 是否初始化过
    }
  },
  mounted() {
    // 编辑模式下监听创建组件事件
    if(this.editModel){
      this.$EventBus.$on('createComponent', () => {
        this.$nextTick(e => this.select(this.configs.children.length - 1));
      });
    }
  },
  watch: {
    'configs.children': {
      handler(){
        this.configs.children.map((e) => {
          if(!e.hasOwnProperty('checked')) {
            e.checked = false;
          }else{
            this.isInit = true;
          }
        });
        if(this.configs.children.length > 0 && !this.isInit){
          this.select(this.configs.children.length - 1);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 选中组件
    select(index){
      // 编辑模式
      if(this.editModel){
        // 将所有的选择框改为未选中状态
        this.configs.children.map(e => e.checked = false);
        // 找到对应的组件，将其状态改为选中状态
        let data = this.configs.children[index];
        data.checked = true;
        this.configs.children.splice(index, 1, data);
        // 通过事件总线发出当前选中组件的事件，并附带选中的组件
        this.$EventBus.$emit("selectComponent", this.configs.children[index]);
      }
    },

    // 上移组件
    moveUP(index){
      let a = this.configs.children[index];
      let b = this.configs.children[index - 1];
      this.configs.children.splice(index, 1, b);
      this.configs.children.splice(index - 1, 1, a);
    },

    // 下移组价
    moveDown(index){
      let a = this.configs.children[index];
      let b = this.configs.children[index + 1];
      this.configs.children.splice(index, 1, b);
      this.configs.children.splice(index + 1, 1, a);
    },

    // 删除组件
    delCom(index){
      this.configs.children.splice(index, 1);
      if(this.configs.children.length < 1) return;
      if(index == 0) this.select(0);
      if(index > 0) this.select(index - 1);
    },

    // 判断是否显示上移按钮
    showMoveUpButton(config, index){
      if(config.hideDel || index <= 0 || this.configs.children[index - 1].comp == "z-baseinfo"){
        return false;
      }else{
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.section{
  width: 100%;
}
.tips{
  font-size: 14px;
  color: #666;
  line-height: 603px;
  text-align: center;
}
.component{
  position: relative;
}
.mask{
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.button{
  width: 35px;
  position: absolute;
  right: -35px;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  span{
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
}
.is-active{
  .mask{
    display: none;;
  }
}
</style>
