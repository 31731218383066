<template>
  <div class="goods-container">
    <p v-if="configs.alias" class="title">{{ configs.alias }}</p>
    <p v-if="configs.list.length < 1" class="none">请在右侧添加商品</p>
    <template v-else>
      <div v-for="(item, index) in configs.list" :key="index" class="goods-item">
        <div class="bg-img-cover-center logo" :style="{'background-image': `url('${ item.logo }')`}"></div>
        <div class="info">
          <p class="van-multi-ellipsis--l2 goods-title">{{ item.name }}</p>
          <p class="price">
            <span class="red" v-if="item.pay_status == 1">{{ "￥" + item.sales_price }}</span>
            <span v-else>仅展示商品</span>
          </p>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: ['configs']
};
</script>

<style lang="scss" scoped>
.goods-container{
  .title{
    color: #000;
    font-size: 0.36rem;
    line-height: 0.72rem;
    padding: 0.25rem;
  }
  .none{
    line-height: 200px;
    color: #fff;
    background-color: #ddd;
    text-align: center;
    font-size: 0.36rem;
  }
  .goods-item{
    width: 6.3rem;
    padding: 0.3rem;
    margin-left: 0.3rem;
    margin-bottom: 0.3rem;
    border-radius: 0.3rem;
    background-color: #fff;
    box-shadow: 0px 10px 20px 0px rgba(0, 34, 70, 0.05);
    &:nth-child(1){
      margin-top: 0.3rem;
    }
    .logo{
      display: inline-block;
      vertical-align: top;
      width: 2rem;
      height: 2rem;
    }
    .info{
      display: inline-block;
      vertical-align: top;
      width: 4rem;
      height: 2rem;
      margin-left: 0.2rem;
      position: relative;
      .goods-title{
        font-size: 0.34rem;
        font-weight: bold;
        position: absolute;
        top: 0;
      }
      .price{
        position: absolute;
        bottom: 0;
        .red{
          color: #ff0000;
        }
      }
    }
  }
}
</style>