<template>
  <div>
    <!-- 组件标题 -->
    <title-comp :configs="configs"></title-comp>

    <!-- 样式选择 -->
    <el-form label-position="left" label-width="80px">
      <el-form-item label="样式选择">
        <div class="style-item" v-for="item in styleList" :key="item.id"
          @click="configs.type = item.id">
          <img :class="{'img__active': configs.type == item.id}" :src="item.url" :alt="item.name">
          <p>{{item.name}}</p>
        </div>
      </el-form-item>
    </el-form>

    <!-- 上传图片组件 -->
    <div class="upload-container">
      <draggable :list="configs.children" style="display:inline-block">
        <transition-group>
          <div class="upload-item" 
            v-for="(item, index) in configs.children" :key="item.id"
            :style="{backgroundImage: `url('${item.img}')`}">
            <span @click="delImage(index)" class="zgcms upload-item__close">&#xe654;</span>
          </div>
        </transition-group>
      </draggable>
      <div class="zgcms upload-item upload-add"
        @click="uploadImage()">&#xe889;</div>
    </div>
  </div>
</template>
<script>
import mixin from './mixin'
import TitleComp from '@/websites/cms/components/editors/Title'
import ResourceDialog from '@/websites/cms/js/dialog'
import draggable from 'vuedraggable'
export default {
  mixins: [mixin],
  props: ['configs'],
  components: { 'title-comp': TitleComp },
  data() {
    return {
      styleList: this.$configs.partnerStyleList
    };
  },
  methods: {
    // 上传图片
    uploadImage(){
      let width = this.styleList[this.configs.type].width;
      let height = this.styleList[this.configs.type].height;
      ResourceDialog.show({
        type: "crooperImage",
        cropWidth: width,
        cropHeight: height,
        fixedArray: [width, height],
        isfixedBox: true,
        imageEnlarge: 2,
        selected: (res) => {
          this.configs.children.push({img: res.url});
        }
      });
    },

    // 删除图片
    delImage(index){
      this.configs.children.splice(index, 1);
    }
  },
};
</script>

<style lang="scss" scoped>
.text-tips{
  color: #bbb;
  font-size: 12px;
}
.style-item{
  width: 82px;
  height: 120px;
  margin-right: 20px;
  text-align: center;
  display: inline-block;
  img{
    width: 80px;
    height: 80px;
    border: 1px solid #ccc;
  }
  .img__active{
    border-color: #6e87bd;
  }
}
.upload-container{
  .upload-item{
    width: 60px;
    height: 60px;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border: 1px solid #d9d9d9;
    vertical-align: top;
    position: relative;
    .upload-item__close{
      padding: 3px;
      color: #fff;
      background-color: rgba(#111, 0.65);
      border-radius: 50%;
      position: absolute;
      top: -9px;
      right: -9px;
    }
  }
  .upload-add{
    line-height: 60px;
    color: #bbb;
    border: 1px dotted #6e87bd;
    text-align: center;
    cursor: pointer;
    &:hover{
      color: #fff;
      background-color: rgba(#111, 0.1);
    }
  }
}
</style>
