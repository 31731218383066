<template>
  <div style="background-color: #fff"
    :style="{'height': divHeight }">
  </div>
</template>

<script>
export default {
  props: ['configs'],
  computed: {
    'divHeight': function(){
      let size = this.configs.height / 50;
      return size + "rem";
    }
  }
};
</script>