import { RandomCode, isObject } from '@/js/Utils'
import CompConfigs from '@/websites/cms/js/template/CompConfigs'
const $configs = JSON.parse(JSON.stringify(CompConfigs))

export class Component {
  id: string; // 微官网ID
  comp: string = ""; // 组件名称
  alias: string = ""; // 微官网名称
  configs: object = {}; // 微官网的值
  children?: Array<Component>; // 微官网组件

  constructor(json?: any) {
    this.id = RandomCode(8);
    // 将字符串反序列化
    if (!isObject(json)) return;
    function deserialize(instance: any, json: any) {
      for (let prop in json) {
        // 判断prop是否在json对象里边，如果不在，结束本次循环，开始下次循环
        if (!json.hasOwnProperty(prop)) continue;
        switch (prop) {
          case "children":
            instance[prop] = json[prop].map(i => deserialize(new Component(), i));
            break;
          default:
            if (Array.isArray(json[prop])) {
              instance[prop] = deserialize([], json[prop]);
            } else if (isObject(json[prop])) {
              instance[prop] = deserialize({}, json[prop]);
            } else {
              instance[prop] = json[prop];
            }
            break;
        }
      }
      return instance;
    }
    deserialize(this, json);
  }
}

export class ZGcms extends Component {
  constructor(json?: any) {
    if (typeof json == "string") json = { alias: json };
    super(json);
  }
}

/**
 * 组件规范
 * @param {string} alias     必须 组件标题
 * @param {string} comp      必须 组件别名，以“z-”开头
 */

export class Title extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "标题",
        icon: {
          id: 0,
          url: 'https://img0.qll-times.com/cdn/zgicon/bm-web-1.png'
        },
        comp: 'z-title'
      };
    }
    super(json);
  }
}

export class Text extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        comp: 'z-text',
        showMainTitle: true,
        showSubtitle: true,
        textAlign: 'left',
        mainTitle: '主标题',
        subtitle: '副标题',
        content: ''
      };
    }
    super(json);
  }
}

export class Picture extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        comp: 'z-picture',
        styleType: 'single',
        imgArry: [''],
        rowsNum: 2,
        columnsNum: 2,
        defaultPicture: $configs.imageBg
      };
    }
    super(json);
  }
}

export class Swipe extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        comp: 'z-swipe',
        styleId: 0,
        imgHeight: [400, 696, 1062],
        imgArry: [{ img: '', jump: false }],
      };
    }
    super(json);
  }
}

export class Video extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        comp: 'z-video',
        styleType: 'big',
        videoArry: [{url: 'https://v.qq.com/', img: '', title: '视频标题1'}]
      };
    }
    super(json);
  }
}

export class ImageText extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        comp: 'z-imagetext',
        alias: '图文',
        styleType: 'single',
        position: 'top',
        fullList: false,
        row: 2,
        column: 2,
        children: [$configs.imageTextItem]
      };
    }
    super(json);
  }
}

export class BaseInfo extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "",
        logo: "",
        desc: "",
        configs: {
          hideDel: true
        },
        comp: 'z-baseinfo'
      };
    }
    super(json);
  }
}

export class RichText extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "",
        desc: "",
        comp: 'z-richtext'
      };
    }
    super(json);
  }
}

export class Tags extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "标签",
        tags: ["标签一", "标签二"],
        comp: 'z-tags'
      };
    }
    super(json);
  }
}

export class Goods extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "",
        list:[],
        comp: 'z-goods'
      };
    }
    super(json);
  }
}

export class PlaceHolder extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "",
        height: 30,
        comp: 'z-placeholder'
      };
    }
    super(json);
  }
}

export class Case extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "",
        list:[],
        comp: 'z-case'
      };
    }
    super(json);
  }
}

export class CompanyDesc extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "企业简介",
        desc: "请填写公司简介...",
        icon: {
          id: 0,
          url: 'https://img0.qll-times.com/cdn/zgicon/bm-web-1.png'
        },
        comp: 'z-companydesc'
      };
    }
    super(json);
  }
}

export class CompanyNews extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "企业资讯",
        icon: {
          id: 7,
          url: 'https://img0.qll-times.com/cdn/zgicon/bm-web-8.png'
        },
        children: [],
        comp: 'z-companynews'
      };
    }
    super(json);
  }
}

export class ManageTeam extends Component {
  constructor(json?: any) {
    let data = JSON.parse(JSON.stringify(CompConfigs));
    if (!json) {
      json = {
        alias: "管理团队",
        icon: {
          id: 2,
          url: 'https://img0.qll-times.com/cdn/zgicon/bm-web-3.png'
        },
        children: [{
          id: RandomCode(8),
          ...$configs.teamMember
        }],
        comp: 'z-manageteam'
      };
    }
    super(json);
  }
}

export class Partner extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "合作伙伴",
        icon: {
          id: 3,
          url: 'https://img0.qll-times.com/cdn/zgicon/bm-web-4.png'
        },
        type: 0,
        children: [],
        comp: 'z-partner'
      };
    }
    super(json);
  }
}

export class ConnectUs extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "联系我们",
        icon: {
          id: 5,
          url: 'https://img0.qll-times.com/cdn/zgicon/bm-web-6.png'
        },
        children: [
          {
            id: RandomCode(8),
            ...$configs.companyAddress
          },
          {
            id: RandomCode(8),
            ...$configs.contactTel
          }
        ],
        comp: 'z-connectus'
      };
    }
    super(json);
  }
}

export class Card extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "名片",
        icon: {
          id: 8,
          url: 'https://img0.qll-times.com/cdn/zgicon/bm-web-9.png'
        },
        children: [{
          id: RandomCode(8),
          ...$configs.cardMember
        }],
        comp: 'z-card'
      };
    }
    super(json);
  }
}

export class CompanyBrochure extends Component {
  constructor(json?: any) {
    if (!json) {
      json = {
        alias: "企业宣传册",
        icon: {
          id: 11,
          url: 'https://img0.qll-times.com/cdn/zgicon/bm-web-12.png'
        },
        children: [],
        comp: 'z-companybrochure'
      };
    }
    super(json);
  }
}