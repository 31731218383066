<template>
  <el-form label-position="left" label-width="100px">
    <!-- 样式切换 -->
    <p class="title">样式选择(切换样式将导致当前的内容丢失)</p>
    <ul class="style-ul">
      <li class="style-li" v-for="(item, index) in styleList" :key="index"
        @click="selectStyle(item)">
        <div class="img-box" :class="{ 'active-img': item.type == configs.styleType }">
          <img :src="item.icon || item.icon1" />
        </div>
        <p>{{ item.name }}</p>
      </li>
    </ul>

    <!-- 文字位置 -->
    <div v-if="configs.styleType == 'single' || configs.styleType == 'list'">
      <p class="title">文字位置</p>
      <ul class="style-ul">
        <li class="style-li" v-for="(item, index) in positionData[configs.styleType]"
          :key="index" @click="configs.position = item.type">
          <div class="img-box" :class="{ 'active-img': configs.position == item.type }">
            <img :src="item.icon || item.icon1" />
          </div>
          <p>{{ item.name }}</p>
        </li>
      </ul>
    </div>

    <!-- 图片上传提示 -->
    <p class="title">图片编辑&nbsp;&nbsp;&nbsp;&nbsp;
      <span class="tips">{{ tipsText.tips1 }}</span>
    </p>
    
    <!-- 行数和列数，只在宫格图文中出现 -->
    <el-form-item v-if="configs.styleType=='sudoku'" label="行数">
      <el-input-number v-model="configs.row" @change="addItem()" :min="1" :max="4"></el-input-number>
    </el-form-item>
    <el-form-item v-if="configs.styleType=='sudoku'" label="列数">
      <el-input-number v-model="configs.column" @change="addItem()" :min="2" :max="4"></el-input-number>
    </el-form-item>
    
    <!-- 图片列表 -->
    <ul class="picture-ul" v-if="configs.styleType != 'single'">
      <template v-for="(item, index) in configs.children">
        <li @click="currentImgIndex = index" class="picture-li"
          :key="index"
          :class="{'active-picture': currentImgIndex == index}"
          :style="{backgroundImage: `url(${item.image || $configs.imageBg})`}">
          <i v-if="showDelItemButton(index)"
            @click.stop="delItem(index)" class="el-icon-circle-close"></i>
        </li>
      </template>
      <li class="picture-add" v-if="showAddItemButton"
        @click="addItem()">
        <span class="el-icon-plus"></span>
      </li>
    </ul>
    
    <!-- 上传图片组件 -->
    <div class="upload-image">
      <file-upload @on-success="onSuccess" size="2" accepts=".png,.jpeg">
        <span class="el-icon-plus add-image" style="width:104px"></span>
      </file-upload>
    </div>
    <p class="size-tips">{{ tipsText.tips2 }}</p>

    <!-- 显示完整列表，只在滑块图文中出现 -->
    <el-form-item v-if="configs.styleType=='slider'" label="显示完整列表">
      <el-radio-group v-model="configs.fullList">
        <el-radio :label="false">1列</el-radio>
        <el-radio :label="true">2列</el-radio>
      </el-radio-group>
    </el-form-item>

    <!-- 文字位置，只在单图文中出现 -->
    <el-form-item v-if="configs.styleType=='single'" label="文字位置">
      <el-radio-group v-model="configs.children[currentImgIndex].align">
        <el-radio label="left">左侧对齐</el-radio>
        <el-radio label="center">水平居中</el-radio>
        <el-radio label="right">右侧对齐</el-radio>
      </el-radio-group>
    </el-form-item>

    <!-- 显示设置，在类型为列表图文时不显示介绍 -->
    <el-form-item label="显示设置">
      <el-checkbox v-model="configs.children[currentImgIndex].title.show">主标题</el-checkbox>
      <el-checkbox v-model="configs.children[currentImgIndex].subtitle.show">副标题</el-checkbox>
      <el-checkbox v-if="configs.styleType != 'list'"
        v-model="configs.children[currentImgIndex].details.show">介绍</el-checkbox>
    </el-form-item>

    <!-- 主标题 -->
    <el-form-item label="主标题" v-if="configs.children[currentImgIndex].title.show">
      <el-input v-model="configs.children[currentImgIndex].title.value" 
        maxlength="20" show-word-limit></el-input>
    </el-form-item>

    <!-- 副标题 -->
    <el-form-item label="副标题" v-if="configs.children[currentImgIndex].subtitle.show">
      <el-input v-model="configs.children[currentImgIndex].subtitle.value" 
        maxlength="24" show-word-limit></el-input>
    </el-form-item>

    <!-- 介绍 -->
    <template v-if="configs.styleType != 'list'">
      <el-form-item label="介绍" v-if="configs.children[currentImgIndex].details.show">
        <el-input v-model="configs.children[currentImgIndex].details.value" 
          type="textarea" rows="4" maxlength="100" show-word-limit></el-input>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
import mixin from './mixin'
import ResourceDialog from '@/websites/cms/js/dialog'
import FileUpload from '@/websites/cms/components/FileUpload'
export default {
  mixins: [mixin],
  props: ["configs"],
  components: { 'file-upload': FileUpload },
  data() {
    return {
      styleList: this.$configs.imageTextStyle,
      positionData: this.$configs.imageTextPosition,
      currentImgIndex: 0, // 当前用户选中的图片顺序
    };
  },
  computed: {
    tipsText: function () {
      let tipsText = {};
      switch (this.configs.styleType) {
        case "single":
          tipsText.tips1 = "(图片大小不超过2M,支持jpg、png格式)";
          tipsText.tips2 = "最佳尺寸：690*400px";
          return tipsText;
        case "list":
          tipsText.tips1 = "(图片大小不超过2M,支持jpg、png格式，最多可上传10张)";
          tipsText.tips2 = "最佳尺寸：450*260px";
          return tipsText;
        case "slider":
          tipsText.tips1 = "(图片大小不超过2M,支持jpg、png格式，最多可上传10张)";
          tipsText.tips2 = "最佳尺寸：420*350px";
          return tipsText;
        case "sudoku":
          tipsText.tips1 = `(图片大小不超过2M,支持jpg、png格式，最多可上传${this.configs.row * this.configs.column}张)`;
          tipsText.tips2 = "最佳尺寸：330*260px";
          return tipsText;
      }
    },

    // 是否显示添加条目按钮
    showAddItemButton: function () {
      if(this.configs.styleType == 'single' || this.configs.styleType == 'sudoku') return false;
      if(this.configs.children.length < 10){
        return true;
      }else{
        return false;
      }
    }
  },
  methods: {
    // 鼠标移入滑块图时切换gif
    switchPictures(item) {
      if(item.type != 'slider') reuturn;
      if (!item.icon || item.icon == item.icon1) {
        item.icon = item.icon2;
      } else {
        item.icon = item.icon1;
      }
    },

    // 选择样式
    selectStyle(item) {
      // 如果点击的是当前选中的类型，则不做处理
      if(this.configs.styleType == item.type) return;
      this.configs.styleType = item.type;

      // 如果当前选中的类型是single和list，则将文字位置重置为默认位置
      if(this.configs.styleType == 'single' || this.configs.styleType == "list"){
        this.configs.position = this.positionData[this.configs.styleType][0].type;
      }

      // 如果选中的是宫格图文
      this.addItem();
      this.currentImgIndex = 0;
    },

    // 上传图片
    onSuccess(data) {
      this.configs.children[this.currentImgIndex].image = data.url;
    },

    // 添加元素
    addItem(){
      if(this.configs.styleType == 'sudoku'){
        let num = this.configs.row * this.configs.column;
        let res = this.configs.children.length - num;
        if(res > 0){
          let tmp = Math.abs(res);
          this.configs.children.splice(num, res);
        }else{
          for(let i = 0; i < Math.abs(res); i++){
            this.configs.children.push(JSON.parse(JSON.stringify(this.$configs.imageTextItem)));
          }
        }
      }else{
        this.configs.children.push(JSON.parse(JSON.stringify(this.$configs.imageTextItem)));
      }
    },

    // 删除元素
    delItem(index){
      console.log('index', index, 'length', this.configs.children.length);
      this.configs.children.splice(index, 1);
      let length = this.configs.children.length;
      if(index == length){
        this.currentImgIndex = index - 1;
      }else if(index < length){
        this.currentImgIndex -= 1;    
      }
      
      console.log('index', index, 'length', this.configs.children.length);
    },

    // 是否显示删除条目按钮
    showDelItemButton(index){
      if(this.configs.styleType == 'list' || this.configs.styleType == 'slider'){
        if(index < 2){
          return false;
        }else{
          return true;
        }
      }else{
        return false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  color: #606266;
  margin-bottom: 1em;
}
.style-ul {
  display: flex;
  margin-bottom: 1em;
  .style-li {
    margin-right: 32px;
    .img-box {
      border: 1px solid transparent;
      img {
        display: block;
        width: 80px;
        height: 80px;
      }
    }

    .active-img {
      border: 1px solid #7fc0f6;
    }
    p {
      padding-top: 15px;
      font-size: 12px;
      text-align: center;
      color: #606266;
    }
  }
}
.tips {
  color: #999;
  font-size: 12px;
  margin-bottom: 1em;
}
.size-tips {
  padding: 15px 0;
  color: #687382;
  font-size: 12px;
}
.picture-ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  .picture-li {
    position: relative;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    margin-bottom: 10px;
    background: transparent no-repeat 50% / cover;
    .el-icon-circle-close {
      position: absolute;
      display: none;
      width: 14px;
      height: 14px;
      top: -7px;
      right: -7px;
      color: red;
      cursor: pointer;
    }
    &:hover {
      .el-icon-circle-close {
        display: block;
      }
    }
  }
  .active-picture {
    border: 1px solid #58b7ff;
    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      width: 14px;
      height: 12px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAYAAAFRKKwcAAAAAXNSR0IArs4c6QAAAYdJREFUKBWVU7tKA1EQPbvE+CBIfCAYjIWIpBMExUISFBtB7DTZSIKVtUU6G/ULRPAHrKzSWGSjwXcjCjYWQbSQoJBCLASR6GaczTJ3s4YILuzeO+ecmTn3DgvUP4kckYo1O+ptBzSBhNYSJu2BkBLCs67eUEctxTDpmeuFbFbn4GyqH6FIlyP2MRO9eHEzVReBjEOKkYVTiZWAbXwy2CrEbBgolNjnBpFeNGHZhF8HFoaAu1eg+CbSP9aVEwqqFqJj18dsdFpiJUiaNFElXAkhK3cFbOe/ycSII9GJSKs/lg1vR4H9e0fgS5p4cLbA1iTQ6QfWzgXhY8oYXMi7Uya9cGNk5CnCY1zid4anPMonDdqqpgXSBeqpfGOXE+KN5VykocBynub4RrLcoc2VNd+pAukjGqxYuOWO3c3k431AnK93k6fx/uWoanPgIaXY7lN9cmYMWBx2ROEAsBMDyh9A5tJNtlmNLc9bVRw4Uu830AKsjwPZR+C67OUk0owclfhvGhDgv+sP0NSI4656Ij0AAAAASUVORK5CYII=)
        no-repeat 50%;
      content: "";
    }
  }
  .picture-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 1px dotted #58b7ff;
  }
}
.upload-image {
  width: 104px;
  height: 104px;
  position: relative;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  .add-image {
    font-size: 30px;
    display: inline-block;
    width: 100%;
    line-height: 104px;
    text-align: center;
    color: #585858;
  }
  .single-upload {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    ::v-deep div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>